import { FC } from 'react';
import { PaymentMethodOutputData } from '../../../../middlewares/Payments/types';
import CardDetailsSelect from './CardDetailsSelect';
import { Nullable } from '../../../../utils/customTypes';

interface PaymentMethodsDetailsSelectProps {
  paymentMethods: Array<PaymentMethodOutputData>;
  handleSelectDetailPaymentMethod: (
    paymentMethod: Nullable<PaymentMethodOutputData>
  ) => void;
}

const PaymentMethodsDetailsSelect: FC<PaymentMethodsDetailsSelectProps> = (
  props
) => {
  const { paymentMethods, handleSelectDetailPaymentMethod } = props;

  return (
    <div className="w-full h-full flex flex-col justify-between pl-8 mb-6">
      <div className="w-full h-full overflow-y-auto">
        <h1 className="text-left font-semibold text-md">
          Selecciona un medio de pago:
        </h1>
        <div className="w-full pr-6 lg:pr-0 lg:pl-4 pt-6 gap-2 flex flex-wrap">
          {paymentMethods.map((paymentMethod, i) => (
            <CardDetailsSelect
              key={`${paymentMethod.id}-${i}`}
              paymentMethod={paymentMethod}
              handleChange={handleSelectDetailPaymentMethod}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PaymentMethodsDetailsSelect;
