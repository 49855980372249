const searcher: { [key: string]: string } = {};

const isImageURL = (logo: string) => {
  if (logo.includes('data:image/png')) return true;
  const regex = /(https?:\/\/.*\.(?:png|jpg|svg))/i;
  return regex.test(logo || '');
};

const logoSearch = (logo: string) => {
  if (logo in searcher) return searcher[logo];
  return logo;
};

const logoParser = (logo: string) => {
  if (!isImageURL(logo) && !(logo in searcher)) {
    return 'https://cdn-icons-png.flaticon.com/512/6592/6592848.png';
  }
  return logoSearch(logo);
};

export default logoParser;
