import { ChangeEvent, FC, useState } from 'react';
import {
  PaymentMethodOutputData,
  SubscriptionCardOutputData,
} from '../../../../middlewares/Payments/types';
import { useParams } from 'react-router-dom';
import { PORTAL_URL } from '../../../../utils/urls';
import axios from 'axios';
import postToRedirection from '../../../../middlewares/Tbk/postToRedirections';
import SubscriptionCardOneClick from './SubscriptionCardOneClick';
import LoadingSpinner from '../../../shared/LoadingSpinner';
import { Nullable } from '../../../../utils/customTypes';

interface OneClickMandateDetailsProps {
  paymentMethod: PaymentMethodOutputData;
  subscriptionCards: Array<SubscriptionCardOutputData>;
  subscriptionCardsLoading: boolean;
  selectedCard: Nullable<SubscriptionCardOutputData>;
  handleSelectSubscriptionCard: (
    subscriptionCard: SubscriptionCardOutputData
  ) => void;
  handleTryDeleteSubscription: (sub: SubscriptionCardOutputData) => void;
}

const OneClickMandateDetails: FC<OneClickMandateDetailsProps> = (props) => {
  const {
    paymentMethod,
    subscriptionCards,
    selectedCard,
    subscriptionCardsLoading,
    handleSelectSubscriptionCard,
    handleTryDeleteSubscription,
  } = props;
  const { id } = useParams();
  const [open, setOpen] = useState<boolean>(false);
  const [inputs, setInputs] = useState<{
    email: string;
    name: string;
  }>({ email: '', name: '' });

  const handleRegisterCard: () => void = async () => {
    const data = {
      email: inputs.email,
      name: inputs.name,
      order: id,
      payment_method: paymentMethod.id,
    };
    const url = `${PORTAL_URL}/subscriptions`;
    const headers = {
      'Content-Type': 'application/json',
    };

    try {
      const res = await axios.post(url, data, {
        headers,
      });

      if (res.status === 201) {
        const { data } = res;
        postToRedirection(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleClick = () => {
    setOpen(!open);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange: (e: ChangeEvent<HTMLInputElement>) => void = (e) => {
    const { name, value } = e.target;
    setInputs((values) => ({
      ...values,
      [name]: value,
    }));
  };

  return (
    <div className="mx-auto mt-2 shadow-xl rounded-xl w-5/6 lg:max-h-52 lg:overflow-y-auto bg-slate-300">
      <div>
        {subscriptionCardsLoading && (
          <div className="flex justify-center">
            <LoadingSpinner message="" />
          </div>
        )}
        {!subscriptionCardsLoading &&
          subscriptionCards.map((subscriptionCard) => (
            <SubscriptionCardOneClick
              key={subscriptionCard.id}
              subscriptionCard={subscriptionCard}
              isSelected={selectedCard?.id === subscriptionCard.id}
              handleSelectSubscriptionCard={handleSelectSubscriptionCard}
              handleTryDeleteSubscription={handleTryDeleteSubscription}
            />
          ))}
      </div>
      <div
        className="mt-auto mx-2 mb-2 flex rounded-lg flex-col space-y-4"
        style={{
          border: open ? 'solid 1px #435FB0' : 'none',
          backgroundColor: open ? '#acbae047' : 'inherit',
        }}
      >
        {!open && (
          <button
            type="button"
            className="
            mt-auto p-4 h-12 text-lg rounded-xl flex items-center
            justify-center font-fs-eliot font-semibold text-white bg-[#435FB0] hover:bg-[#5271cc]
            transition-all duration-200
          "
            onClick={handleClick}
          >
            Agregar{' '}
            {paymentMethod.handler.toLowerCase().includes('pac') ||
            paymentMethod.handler.toLowerCase().includes('fintoc')
              ? 'tarjeta'
              : 'mandato'}
          </button>
        )}
        {open && (
          <div className="form new_subscription_form h-full flex flex-col my-1">
            <div className="field flex justify-around align-middle mb-2">
              <label
                className="input-group-addon text-sm flex items-center"
                htmlFor="name"
              >
                Nombre asociado:
              </label>
              <input
                type="text"
                name="name"
                id="name"
                value={inputs.name}
                onChange={handleChange}
                className="form-control mx-2 px-2 py-1 text-sm rounded-md"
              />
            </div>
            <div className="field flex justify-around mb-2">
              <label
                className="input-group-addon text-sm flex items-center"
                htmlFor="email"
              >
                Correo asociado:
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={inputs.email}
                onChange={handleChange}
                className="form-control mx-2 px-2 py-1 text-sm rounded-md"
              />
            </div>
            <div>
              <button
                id="register_new_card-<%= payment_method.id %>"
                className="
                  m-auto w-1/2 p-4 h-8 text-lg rounded-xl flex items-center
                  justify-center font-fs-eliot font-semibold text-white bg-[#435FB0] hover:bg-[#536cb9]
                  transition-all duration-200
                "
                type="submit"
                disabled={inputs.email === '' || inputs.name === ''}
                onClick={handleRegisterCard}
              >
                Registrar
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OneClickMandateDetails;
