import { FC } from 'react';
import {
  PaymentMethodOutputData,
  SubscriptionCardOutputData,
} from '../../../../middlewares/Payments/types';
import { Nullable } from '../../../../utils/customTypes';
import PaymentMethodsDetailsSelect from './PaymentMethodDetailsSelect';
import useColors from '../../../../hooks/useColors';
import SelectedPaymentDetails from './SelectedPaymentDetails';

interface BaseDetailsSelectProps {
  subscriptionCards: Array<SubscriptionCardOutputData>;
  subscriptionCardsLoading: boolean;
  selectedCard: Nullable<SubscriptionCardOutputData>;
  selectedGeneralPaymentMethod: Nullable<PaymentMethodOutputData>;
  selectedDetailPaymentMethod: Nullable<PaymentMethodOutputData>;
  detailsPaymentMethods: PaymentMethodOutputData[];
  handleSelectDetailPaymentMethod: (
    paymentMethod: Nullable<PaymentMethodOutputData>
  ) => void;
  handleSelectSubscriptionCard: (
    subscriptionCard: SubscriptionCardOutputData
  ) => void;
  handleTryDeleteSubscription: (sub: SubscriptionCardOutputData) => void;
}

const BaseDetailsSelect: FC<BaseDetailsSelectProps> = (props) => {
  const {
    subscriptionCards,
    subscriptionCardsLoading,
    selectedCard,
    selectedGeneralPaymentMethod,
    selectedDetailPaymentMethod,
    detailsPaymentMethods,
    handleSelectDetailPaymentMethod,
    handleSelectSubscriptionCard,
    handleTryDeleteSubscription,
  } = props;
  const { secondaryColor } = useColors();

  return (
    <>
      {selectedGeneralPaymentMethod === null && (
        <div className="w-full h-full flex flex-col justify-between p-12">
          <div className="w-full my-auto">
            <h1
              className="font-medium text-3xl"
              style={{ color: secondaryColor }}
            >
              Selecciona una forma de pago para continuar con la transacción
            </h1>
          </div>
        </div>
      )}
      {selectedGeneralPaymentMethod !== null &&
        selectedDetailPaymentMethod === null && (
          <PaymentMethodsDetailsSelect
            paymentMethods={detailsPaymentMethods}
            handleSelectDetailPaymentMethod={handleSelectDetailPaymentMethod}
          />
        )}
      {selectedDetailPaymentMethod !== null && (
        <SelectedPaymentDetails
          paymentMethod={selectedDetailPaymentMethod}
          subscriptionCards={subscriptionCards}
          subscriptionCardsLoading={subscriptionCardsLoading}
          selectedCard={selectedCard}
          isPartOfMultiPaymentMethods={detailsPaymentMethods.length > 1}
          handleUnSelect={handleSelectDetailPaymentMethod}
          handleSelectSubscriptionCard={handleSelectSubscriptionCard}
          handleTryDeleteSubscription={handleTryDeleteSubscription}
        />
      )}
    </>
  );
};

export default BaseDetailsSelect;
