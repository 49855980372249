import { FC } from 'react';

// middlewaress
import {
  OpenInputType,
  OpenParserOutputData,
} from '../../../../middlewares/Open/openTypes';

// components
import Layout from '../../common/Layout';
import OpenHeader from '../Header/OpenHeader';
import BaseFields from '../OpenTransaction/BaseFields';

// utils
import { Nullable } from '../../../../utils/customTypes';

interface OpenBaseTemplateOneProps {
  apiData: OpenParserOutputData;
  onChangeInput: (name: string, value: string | number) => void;
  inputs: OpenInputType;
  loadedInputs: Nullable<OpenInputType>;
  loadOpenStoredInputs: () => void;
  storeInputsOnLocal: () => void;
  dismissedFields: { [key: string]: boolean };
  onDismissField: (name: string, dismiss: boolean) => void;
  handleSubmit: () => void;
  recheckValidations: boolean;
  setRecheckValidations: React.Dispatch<React.SetStateAction<boolean>>;
}

const OpenTransactionBaseTemplateOne: FC<OpenBaseTemplateOneProps> = (
  props
) => {
  const {
    apiData,
    inputs,
    loadedInputs,
    loadOpenStoredInputs,
    storeInputsOnLocal,
    onChangeInput,
    dismissedFields,
    onDismissField,
    handleSubmit,
    recheckValidations,
    setRecheckValidations,
  } = props;

  const loadedInputsMetadata = {
    name: apiData.paymentRequest.name === '',
    email: true,
    amount:
      apiData.paymentRequest.amount === 0 ||
      apiData.paymentRequest.amount === null,
    extra: apiData.paymentRequest.linkConfigurations.length > 0,
  };

  return (
    <Layout shape="bigger">
      <div className="h-auto w-full flex flex-col">
        <OpenHeader
          merchantLogo={apiData.merchant.logo}
          merchantName={apiData.merchant.name}
          storable={!!apiData.storable}
          loadedInputs={loadedInputs}
          loadedInputsMetadata={loadedInputsMetadata}
          loadOpenStoredInputs={loadOpenStoredInputs}
        />
        <BaseFields
          paymentRequest={apiData.paymentRequest}
          onChangeInput={onChangeInput}
          inputs={inputs}
          dismissedFields={dismissedFields}
          onDismissField={onDismissField}
          handleSubmit={handleSubmit}
          storeInputsOnLocal={storeInputsOnLocal}
          storable={!!apiData.storable}
          recheckValidations={recheckValidations}
          setRecheckValidations={setRecheckValidations}
        />
      </div>
    </Layout>
  );
};

export default OpenTransactionBaseTemplateOne;
