import { FC, useState } from 'react';
import Layout from '../../common/Layout';
import { ReturnParserOutputData } from '../../../../middlewares/Return/returnTypes';
import PaymentInformation from '../Payment/PaymentInformation';
import PaymentStatus from '../Payment/PaymentStatus';
import useIsTemplate from '../../../../hooks/useIsTemplate';
import ColorPickerIcon from '../../../shared/ColorPickerIcon';
import ChangeColorModal from '../../common/ChangeColorModal';
import useColors from '../../../../hooks/useColors';
import SubscriptionInformation from '../Subscription/SubscriptionInformation';
import SubscriptionStatus from '../Subscription/SubscriptionStatus';

interface ReturnBaseTemplateOneProps {
  apiData: ReturnParserOutputData;
}

const ReturnBaseTemplateOne: FC<ReturnBaseTemplateOneProps> = (props) => {
  const { apiData } = props;
  const { primaryColor } = useColors();
  const { isTemplate } = useIsTemplate();
  const [showColorModal, setShowColorModal] = useState<boolean>(false);

  return (
    <>
      <Layout shape="smaller">
        <div className="w-full">
          <div className="h-[30%] w-full flex border-b-2 border-amber-300">
            <h1 className="w-full text-3xl font-medium my-auto">
              {apiData.isSubscription
                ? 'Información de la Suscripción'
                : 'Transacción finalizada'}
            </h1>
          </div>
          {!apiData.isSubscription && (
            <div className="h-[60%] w-full flex justify-center flex-col md:flex-row">
              <PaymentInformation
                transactionRequest={apiData.transactionRequest}
                merchant={apiData.merchant}
              />
              <PaymentStatus
                payment={apiData.payment}
                transactionRequest={apiData.transactionRequest}
              />
            </div>
          )}
          {apiData.isSubscription && (
            <div className="h-[60%] w-full flex justify-center flex-col md:flex-row">
              <SubscriptionInformation
                transactionRequest={apiData.transactionRequest}
                merchant={apiData.merchant}
              />
              <SubscriptionStatus subscription={apiData.subscription} />
            </div>
          )}
          {apiData.merchant.host && (
            <div className="mb-2">
              <p className="center black-text" style={{ textAlign: 'center' }}>
                Para volver al sitio de {apiData.merchant.name}, sigue este{' '}
                <a
                  href={apiData.merchant.host}
                  className="border-b-2"
                  style={{ color: primaryColor, borderColor: primaryColor }}
                >
                  link
                </a>
                .
              </p>
            </div>
          )}
        </div>
      </Layout>
      {isTemplate && <ColorPickerIcon openModal={setShowColorModal} />}
      {isTemplate && showColorModal && (
        <ChangeColorModal closeModal={setShowColorModal} />
      )}
    </>
  );
};

export default ReturnBaseTemplateOne;
