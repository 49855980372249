import { OpenParserInputData } from '../openTypes';

const templateOpenData: OpenParserInputData = {
  status: 200,
  data: {
    generated_link: null,
    subscription: false,
    storable: false,
    metrics_token: '0',
    merchant: {
      id: 1,
      name: 'Prueba',
      logo: null,
      colors: '{}',
      preferred_template: 0,
    },
    payment_request: {
      id: 'aaa',
      identifier: null,
      name: 'Cliente de prueba',
      email: null,
      currency: null,
      amount: 0,
      blank_amount: false,
      has_iva: false,
      has_link_configurations: true,
      link_configurations: [
        {
          id: 963,
          custom_field_type: 'LinkConfigurations::Text',
          position: 1100,
          hidden: false,
          label: 'Campo de Texto',
          dismissable: false,
          value: '',
          name: 'criadero',
          placeholder: '',
          validations: [],
          data: null,
        },
        {
          id: 964,
          custom_field_type: 'LinkConfigurations::RemoteText',
          position: 1100,
          hidden: false,
          label: 'Campo Remoto - Nombre Persona',
          dismissable: false,
          value: '',
          name: 'nombre-persona',
          placeholder: '',
          validations: [],
          data: '{"type":"FakeNameIds","field":"identifier","linked":{"target":"rut-persona","field":"name"}}',
        },
        {
          id: 965,
          custom_field_type: 'LinkConfigurations::RemoteText',
          position: 1100,
          hidden: false,
          label: 'Campo Remoto - Identificador Persona',
          dismissable: false,
          value: '',
          name: 'rut-persona',
          placeholder: '',
          validations: [],
          data: '{"type":"FakeNameIds","field":"name","linked":{"target":"nombre-persona","field":"identifier"}}',
        },
        {
          id: 972,
          custom_field_type: 'LinkConfigurations::Description',
          position: 1100,
          hidden: false,
          label: 'Campo de descripción',
          dismissable: false,
          value: '',
          name: 'description-field',
          placeholder: '',
          validations: [],
          data: null,
        },
      ],
    },
  },
};

export default templateOpenData;
