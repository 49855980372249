import { FC, FocusEvent, useState } from 'react';
import { Nullable } from '../../../../utils/customTypes';
import { OpenInputType } from '../../../../middlewares/Open/openTypes';
import { ReactComponent as ChevronDownIcon } from '../../../../assets/feather/chevron-down.svg';
import useColors from '../../../../hooks/useColors';

interface LoadInputButtonProps {
  isFocus: boolean;
  setIsFocus: React.Dispatch<React.SetStateAction<boolean>>;
  hasInputsToLoad: boolean;
}

const LoadInputsButton: FC<LoadInputButtonProps> = (props) => {
  const { isFocus, setIsFocus, hasInputsToLoad } = props;
  const { primaryColor, secondaryColor } = useColors();
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const handleOnClick = () => {
    setIsFocus(!isFocus);
  };

  return (
    <button
      id="dropdownButton"
      className="w-11/12 lg:w-full mx-auto lg:mb-0 font-semibold rounded-lg shadow-md hover:shadow-sm transition-all text-sm px-5 py-2.5 text-center inline-flex items-stretch justify-between"
      type="button"
      onClick={handleOnClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        color: isHover ? secondaryColor : 'black',
        borderWidth: '1px',
        borderColor: isHover ? secondaryColor : primaryColor,
        display: hasInputsToLoad ? 'inherit' : 'none',
      }}
      disabled={!hasInputsToLoad}
    >
      Datos guardados:
      <ChevronDownIcon />
    </button>
  );
};

interface LoadInputsSelectorProps {
  loadedInputs: Nullable<OpenInputType>;
  loadOpenStoredInputs: () => void;
  loadedInputsMetadata: {
    name: boolean;
    email: boolean;
    amount: boolean;
    extra: boolean;
  };
}

const LoadInputsSelector: FC<LoadInputsSelectorProps> = (props) => {
  const { loadedInputs, loadOpenStoredInputs, loadedInputsMetadata } = props;
  const [isFocus, setIsFocus] = useState<boolean>(false);

  const hasInputsToLoad =
    Object.values(loadedInputs || '{}').filter(
      (value) => typeof value === 'string'
    ).length > 0;

  const handleBlur = (e: FocusEvent<HTMLElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setIsFocus(false);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    loadOpenStoredInputs();
    setIsFocus(false);
  };

  return (
    <div className="flex items-start mt-3 lg:mt-0" onBlur={handleBlur}>
      <LoadInputsButton
        isFocus={isFocus}
        setIsFocus={setIsFocus}
        hasInputsToLoad={hasInputsToLoad}
      />
      <div
        className="z-10 left-0 right-0 lg:right-auto lg:left-auto absolute mt-12 mx-auto lg:mx-0 bg-white divide-y divide-gray-100 rounded-lg shadow w-11/12 lg:w-1/4"
        style={{
          display: isFocus ? 'block' : 'none',
        }}
      >
        <ul
          className="text-sm text-gray-700"
          aria-labelledby="dropdownRightEndButton"
        >
          <li>
            <button
              type="button"
              className="block px-4 py-2 w-full hover:bg-gray-100 rounded-lg"
              onClick={handleClick}
            >
              {loadedInputsMetadata.name && loadedInputs?.name && (
                <p>
                  <span className="font-semibold">Nombre:</span>{' '}
                  {loadedInputs.name}
                </p>
              )}
              {loadedInputsMetadata.email && loadedInputs?.email && (
                <p>
                  <span className="font-semibold">Email:</span>{' '}
                  {loadedInputs.email}
                </p>
              )}
              {loadedInputsMetadata.amount && loadedInputs?.amount && (
                <p>
                  <span className="font-semibold">Monto:</span>{' '}
                  {loadedInputs.amount}
                </p>
              )}
              {loadedInputsMetadata.extra && <p>...</p>}
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default LoadInputsSelector;
