import { useParams } from 'react-router-dom';

const ErrorScreen = () => {
  const { id } = useParams();

  return (
    <div className="w-screen h-screen flex">
      <div className="h-full w-full flex flex-col overflow-auto">
        <div className="card m-auto flex flex-1 w-full lg:w-[60vw] lg:max-h-[60vh] rounded-lg overflow-hidden shadow-xl p-0 lg:p-[60px]">
          <div className="my-auto p-6 w-full">
            <h1 className="text-3xl py-8 text-primary border-primary border-b-4 text-start font-extrabold">
              Error 404
            </h1>
            <p className="text-lg font-medium text-start mt-6">
              No se pudo mostrar los datos de la transacción &quot;{id}&quot;,
              reintenta accediendo al link original.
            </p>
            <p className="text-lg font-medium text-start mt-6">
              Si eso no funciona contacta con el comercio al que quieres pagar.
            </p>
            <div className="flex w-full mt-16">
              <div className="flex m-auto items-center">
                <span className="mr-2 text-sm">powered by</span>
                <span className="font-system-ui font-bold text-lg">Pago</span>
                <span className="font-system-ui font-bold text-lg text-[#00AAFF]">
                  Hub
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorScreen;
