import { ChangeEvent, FC, ReactNode, memo, useState } from 'react';
import useColors from '../../../hooks/useColors';

interface CustomInputProps {
  required?: boolean;
  id: string;
  type?: string;
  placeholder: string;
  name: string;
  value: string | number | undefined;
  disabled?: boolean;
  min?: number;
  max?: number;
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  icon?: ReactNode;
  currency?: ReactNode;
  autoComplete?: 'on' | 'off';
}

const CustomInput: FC<CustomInputProps> = (props) => {
  const { primaryColor } = useColors();
  const [isFocus, setIsFocus] = useState<boolean>(false);

  return (
    <div className="relative">
      {props.icon && (
        <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
          {props.icon}
        </div>
      )}
      <input
        {...props}
        onChange={props.handleChange ? props.handleChange : () => null}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        className="bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5 disabled:bg-[#D2D2D2] [&::-webkit-inner-spin-button]:appearance-none"
        style={{
          borderColor: isFocus ? primaryColor : '#D2D2D2',
          outlineColor: isFocus ? primaryColor : 'transparent',
          paddingInlineStart: props.icon ? '2.5rem' : '0.625rem',
          paddingInlineEnd: props.currency ? '3.3rem' : '0.625rem',
        }}
      />
      {props.currency && (
        <div className="absolute inset-y-0 end-0 flex items-center pe-3.5 pointer-events-none text-gray-500">
          {props.currency}
        </div>
      )}
    </div>
  );
};

export default memo(CustomInput);
