import { FC, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

// hooks
import useColors from '../../hooks/useColors';
import useIsTemplate from '../../hooks/useIsTemplate';
import usePortalFetch from '../../hooks/usePortalFetch';

// middlewares
import templateOpenData from '../../middlewares/Open/template/openTemplate';
import {
  OpenInputType,
  OpenParserOutputData,
  SubscriptionInputType,
} from '../../middlewares/Open/openTypes';
import openParser, { templateParser } from '../../middlewares/Open/openParser';
import subscriptionTemplateData from '../../middlewares/Open/template/subscriptionTemplate';
import subscriptionOpenTemplateData from '../../middlewares/Open/template/subscriptionOpenTemplate';

// components
import LoadingScreen from '../../components/common/LoadingScreen';
import OpenBaseTemplateOne from '../../components/t1/open/Base/OpenBaseTemplateOne';
import TokenErrorScreen from '../../components/common/TokenErrorScreen';

// utils
import awaitFunction from '../../utils/awaitFunction';
import isPossibleTemplate from '../../utils/isPossibleTemplates';
import { Nullable } from '../../utils/customTypes';

const OpenTemplate: FC = () => {
  const { type } = useParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { saveIsTemplate } = useIsTemplate();
  const { savePrimaryColor, saveSecondaryColor } = useColors();
  const [validTemplate, setValidTemplate] = useState<boolean>(false);
  const [loadingPage, setLoadingPage] = useState<boolean>(true);
  const [recheckValidations, setRecheckValidations] = useState<boolean>(false);
  const loadedInputs = null;
  const [openInputs, setOpenInputs] = useState<OpenInputType>({
    name: '',
    email: '',
    amount: 0,
  });
  const [subscriptionInputs, setSubscriptionInputs] =
    useState<SubscriptionInputType>({
      name: '',
      email: '',
      amount: '',
      termsAndConditions: false,
    });
  const [dismissedFields, setDismissedFields] = useState<{
    [key: string]: boolean;
  }>({});
  const {
    apiData: validationData,
    loading,
    error: validationError,
  } = usePortalFetch(`payments/template?token=${token}`, templateParser);
  const [apiData, setApiData] = useState<Nullable<OpenParserOutputData>>(null);
  const [error, setError] = useState<string | boolean>(false);

  useEffect(() => {
    saveIsTemplate(true);
  }, [saveIsTemplate]);

  useEffect(() => {
    if (!validationData) return;
    const templateData = openParser(
      type === 'open'
        ? templateOpenData
        : type === 'subscription'
          ? subscriptionTemplateData
          : subscriptionOpenTemplateData
    );
    setApiData({
      ...templateData,
      merchant: validationData.merchant,
    });
  }, [validationData, type]);

  useEffect(() => {
    setError(
      !['open', 'subscription', 'subscription-open'].includes(type as string) ||
        validationError
    );
  }, [validationError, type]);

  useEffect(() => {
    if (loading || !apiData) return;

    if (!apiData.isSubscription) {
      setOpenInputs((values) => ({
        ...values,
        name: apiData.paymentRequest?.name ? apiData.paymentRequest?.name : '',
        amount: apiData.paymentRequest?.amount
          ? apiData.paymentRequest?.amount
          : '',
        ...(apiData.paymentRequest?.linkConfigurations || [])
          .map((linkConfiguration) => ({
            [linkConfiguration.name]: linkConfiguration.value,
          }))
          .reduce((prev, curr) => ({ ...prev, ...curr }), {}),
      }));
    } else {
      setSubscriptionInputs((values) => ({
        ...values,
        name: apiData.paymentRequest?.name ? apiData.paymentRequest?.name : '',
        email: apiData.paymentRequest?.email
          ? apiData.paymentRequest?.email
          : '',
        amount: apiData.paymentRequest?.amount
          ? apiData.paymentRequest?.amount
          : '',
      }));
    }
  }, [apiData, loading]);

  useEffect(() => {
    if (loading) {
      setLoadingPage(true);
      return;
    }
    if (error || !apiData) {
      setLoadingPage(false);
      return;
    }

    const primaryColor = apiData?.merchant?.primaryColor;
    const secondaryColor = apiData?.merchant?.secondaryColor;

    setValidTemplate(
      isPossibleTemplate(apiData?.merchant?.preferredTemplate as number)
    );

    const awaitColorChange = async (
      primaryColor: Nullable<string>,
      secondaryColor: Nullable<string>
    ) => {
      savePrimaryColor(primaryColor as string);
      saveSecondaryColor(secondaryColor as string);
      await awaitFunction(500, () => null);
      setLoadingPage(false);
    };
    awaitColorChange(primaryColor, secondaryColor);
  }, [apiData, loading, error, savePrimaryColor, saveSecondaryColor]);

  const storeInputsOnLocal = () => null;

  const loadOpenStoredInputs = () => null;

  const onChangeInput = (name: string, value: string | number) => {
    setOpenInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeSubscriptionInput = (
    name: keyof SubscriptionInputType,
    value: string | number | boolean,
    type: string
  ) => {
    if (type === 'checkbox') {
      setSubscriptionInputs((values) => ({
        ...values,
        [name]: !subscriptionInputs[name],
      }));
      return;
    }
    setSubscriptionInputs((values) => ({
      ...values,
      [name]: value,
    }));
  };

  const onDismissField = (name: string, dismiss: boolean) => {
    if (dismiss) {
      setOpenInputs((values) => {
        // dismissedValue will not be used
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [name]: dismissedValue, ...updatedValues } = values;
        return updatedValues as OpenInputType;
      });
    }
    setDismissedFields((values) => ({ ...values, [name]: dismiss }));
  };

  const handleSubmit = () => {
    console.log(`Submitting: ${openInputs}`);
    window.location.href = `/payments/template?token=${token}&price=${openInputs.amount || subscriptionInputs.amount}`;
  };

  return (
    <>
      {loadingPage && <LoadingScreen message={null} />}
      {!loadingPage && apiData && validTemplate && (
        <OpenBaseTemplateOne
          openInputs={openInputs}
          subscriptionInputs={subscriptionInputs}
          loadedInputs={loadedInputs}
          loadOpenStoredInputs={loadOpenStoredInputs}
          storeInputsOnLocal={storeInputsOnLocal}
          handleChangeOpenInput={onChangeInput}
          handleChangeSubscriptionInput={handleChangeSubscriptionInput}
          apiData={apiData}
          dismissedFields={dismissedFields}
          onDismissField={onDismissField}
          handleOpenSubmit={handleSubmit}
          handleSubscriptionSubmit={handleSubmit}
          recheckValidations={recheckValidations}
          setRecheckValidations={setRecheckValidations}
        />
      )}
      {!loadingPage && (error || !validTemplate) && <TokenErrorScreen />}
    </>
  );
};

export default OpenTemplate;
