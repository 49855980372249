import { useContext } from 'react';
import { IsTemplateContext } from '../contexts/isTemplateContext';

const useIsTemplate = () => {
  const { isTemplate, saveIsTemplate } = useContext(IsTemplateContext);

  return {
    isTemplate,
    saveIsTemplate,
  };
};

export default useIsTemplate;
