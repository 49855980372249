import {
  MerchantInputData,
  MerchantOutputData,
  PaymentInputData,
  PaymentOutputData,
  ReturnParserInputData,
  ReturnParserOutputData,
  SubscriptionInputData,
  SubscriptionOutputData,
  TemplateInputType,
  TemplateOutputType,
  TransactionRequestInputData,
  TransactionRequestOutputData,
} from './returnTypes';

const parseMerchant: (merchantData: MerchantInputData) => MerchantOutputData = (
  merchantData
) => {
  const {
    id,
    name,
    host,
    logo,
    colors,
    preferred_template: preferredTemplate,
    google_analytics_token: ganalyticsToken,
  } = merchantData;
  const parsedMerchant = {
    id,
    name,
    host,
    logo,
    primaryColor: null,
    secondaryColor: null,
    preferredTemplate,
    ganalyticsToken,
  };
  if (colors !== null) {
    const parsedColors = JSON.parse(colors);
    return {
      ...parsedMerchant,
      primaryColor: parsedColors.primaryColor,
      secondaryColor: parsedColors.secondaryColor,
    };
  }
  return parsedMerchant;
};

const parseTransactionRequest: (
  transactionRequestData: TransactionRequestInputData
) => TransactionRequestOutputData = (
  transactionRequestData: TransactionRequestInputData
) => {
  const { slug, amount, identifier, name, email, caducated, errors } =
    transactionRequestData;
  return {
    slug,
    amount,
    identifier,
    name,
    email,
    caducated,
    errors: errors.reduce((prev, curr) => [...prev, ...curr], []),
  };
};

const parsePayment: (paymentData: PaymentInputData) => PaymentOutputData = (
  paymentData: PaymentInputData
) => {
  const { valid, status } = paymentData;

  return {
    valid,
    statusMessage: status,
  };
};

const parseSubscription: (
  subscriptionData: SubscriptionInputData
) => SubscriptionOutputData = (subscriptionData: SubscriptionInputData) => {
  const { id, message, missing } = subscriptionData;

  return {
    id,
    message,
    missing,
  };
};

export const templateParser: (data: TemplateInputType) => TemplateOutputType = (
  data: TemplateInputType
) => {
  const {
    data: { merchant: merchantData, valid },
  } = data;

  return {
    merchant: parseMerchant(merchantData),
    valid,
  };
};

const returnParser: (data: ReturnParserInputData) => ReturnParserOutputData = (
  data: ReturnParserInputData
) => {
  const {
    data: {
      merchant: merchantData,
      transaction_request: transactionRequestData,
      payment: paymentData,
      subscription: subscriptionData,
      is_subscription: isSubscription,
    },
  } = data;

  const parsedData = {
    merchant: parseMerchant(merchantData),
    transactionRequest: parseTransactionRequest(transactionRequestData),
    payment: parsePayment(paymentData),
    subscription: parseSubscription(subscriptionData),
    isSubscription,
  };

  return parsedData;
};

export default returnParser;
