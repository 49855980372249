import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

// hooks
import useColors from '../../hooks/useColors';
import useIsTemplate from '../../hooks/useIsTemplate';

// middlewares
import returnParser, {
  templateParser,
} from '../../middlewares/Return/returnParser';
import returnPaymentData from '../../middlewares/Return/template/returnPaymentData';
import returnSubscriptionData from '../../middlewares/Return/template/returnSubscriptionData';
import { ReturnParserOutputData } from '../../middlewares/Return/returnTypes';

// components
import LoadingScreen from '../../components/common/LoadingScreen';
import ReturnBaseTemplateOne from '../../components/t1/return/Base/ReturnBaseTemplateOne';
import TokenErrorScreen from '../../components/common/TokenErrorScreen';

// utils
import awaitFunction from '../../utils/awaitFunction';
import isPossibleTemplate from '../../utils/isPossibleTemplates';
import usePortalFetch from '../../hooks/usePortalFetch';
import { Nullable } from '../../utils/customTypes';

const ReturnTemplate: FC = () => {
  const { saveIsTemplate } = useIsTemplate();
  const [searchParams] = useSearchParams();
  const { savePrimaryColor, saveSecondaryColor } = useColors();
  const [validTemplate, setValidTemplate] = useState<boolean>(false);
  const [loadingPage, setLoadingPage] = useState<boolean>(true);
  const token = searchParams.get('token');
  const price = searchParams.get('price');
  const {
    apiData: validationData,
    loading,
    error,
  } = usePortalFetch(
    `payments/template?token=${token}&price=${price}&type=payment`,
    templateParser
  );
  const [apiData, setApiData] =
    useState<Nullable<ReturnParserOutputData>>(null);

  useEffect(() => {
    saveIsTemplate(true);
  }, [saveIsTemplate]);

  useEffect(() => {
    if (!validationData) return;
    const templateData = returnParser(
      searchParams.get('subscription')
        ? returnSubscriptionData
        : returnPaymentData
    );
    setApiData({
      ...templateData,
      merchant: validationData.merchant,
      transactionRequest: {
        ...templateData.transactionRequest,
        amount:
          parseInt(price as string) || templateData.transactionRequest.amount,
      },
    });
  }, [validationData, searchParams, price]);

  useEffect(() => {
    if (loading) {
      setLoadingPage(true);
      return;
    }
    if (error || !apiData) {
      setLoadingPage(false);
      return;
    }

    const primaryColor = apiData?.merchant?.primaryColor;
    const secondaryColor = apiData?.merchant?.secondaryColor;

    setValidTemplate(
      isPossibleTemplate(apiData?.merchant?.preferredTemplate as number)
    );

    const awaitColorChange = async (
      primaryColor: Nullable<string>,
      secondaryColor: Nullable<string>
    ) => {
      savePrimaryColor(primaryColor as string);
      saveSecondaryColor(secondaryColor as string);
      await awaitFunction(500, () => null);
      setLoadingPage(false);
    };
    awaitColorChange(primaryColor, secondaryColor);
  }, [apiData, loading, error, savePrimaryColor, saveSecondaryColor]);

  return (
    <>
      {loadingPage && <LoadingScreen message={null} />}
      {!loadingPage && apiData && validTemplate && (
        <ReturnBaseTemplateOne apiData={apiData} />
      )}
      {!loadingPage && (error || !validTemplate) && (
        <TokenErrorScreen missingPrice={price === null} />
      )}
    </>
  );
};

export default ReturnTemplate;
