import { FC, FormEvent, useState } from 'react';
import PaymentMethodSelect from './PaymentMethodSelect';
import Button from '../../../shared/Button';
import {
  PaymentMethodOutputData,
  SubscriptionCardOutputData,
} from '../../../../middlewares/Payments/types';
import { Nullable } from '../../../../utils/customTypes';
import BaseDetailsSelect from '../DetailsSelect/BaseDetailsSelect';
import { PORTAL_URL } from '../../../../utils/urls';
import { useParams, useSearchParams } from 'react-router-dom';
import isSubscriptionPaymentMethod from '../../../../utils/payments/isSubscriptionPaymentMethod';
import useIsTemplate from '../../../../hooks/useIsTemplate';
// import sendMetrics from '../../../../utils/metrics/sendMetrics';

interface PaymentMethodSelectorProps {
  canPay: boolean;
  loadingPayButton: boolean;
  subscriptionCards: Array<SubscriptionCardOutputData>;
  subscriptionCardsLoading: boolean;
  selectedCard: Nullable<SubscriptionCardOutputData>;
  paymentMethods: PaymentMethodOutputData[];
  detailsPaymentMethods: PaymentMethodOutputData[];
  selectedGeneralPaymentMethod: Nullable<PaymentMethodOutputData>;
  metricsToken: Nullable<string>;
  handleSelectGeneralPaymentMethod: (paymentMethodSlug: string) => void;
  selectedDetailPaymentMethod: Nullable<PaymentMethodOutputData>;
  handleSelectDetailPaymentMethod: (
    paymentMethod: Nullable<PaymentMethodOutputData>
  ) => void;
  handleSelectSubscriptionCard: (
    subscriptionCard: SubscriptionCardOutputData
  ) => void;
  handleTryDeleteSubscription: (sub: SubscriptionCardOutputData) => void;
  handlePayClick: () => void;
}

const PaymentMethodSelector: FC<PaymentMethodSelectorProps> = (props) => {
  const {
    canPay,
    loadingPayButton,
    subscriptionCards,
    subscriptionCardsLoading,
    metricsToken,
    selectedCard,
    paymentMethods,
    detailsPaymentMethods,
    selectedGeneralPaymentMethod,
    handleSelectGeneralPaymentMethod,
    selectedDetailPaymentMethod,
    handleSelectDetailPaymentMethod,
    handleSelectSubscriptionCard,
    handleTryDeleteSubscription,
    handlePayClick,
  } = props;
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const price = searchParams.get('price');
  const { isTemplate } = useIsTemplate();
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    setLoadingSubmit(true);
    if (isTemplate) {
      e.preventDefault();
      window.location.href = `/payments/template/return?token=${token}&price=${price}`;
      setLoadingSubmit(false);
    }
  };

  return (
    <div className="h-3/4 flex flex-col lg:flex-row">
      <div
        id="payment-method-list"
        className="mt-8 px-8 w-full lg:mt-0 lg:px-0 lg:w-1/2 lg:border-r-2 lg:border-r-amber-300 overflow-y-auto"
      >
        <h1 className="text-left font-semibold text-md">
          Selecciona una forma de pago:
        </h1>
        <div className="px-6 pt-6">
          {paymentMethods.map((paymentMethod, i) => (
            <PaymentMethodSelect
              key={`${paymentMethod.id}-${i}`}
              paymentMethodId={paymentMethod.id}
              paymentMethodLogoURL={paymentMethod.logo || ''}
              paymentMethodTitle={paymentMethod.title}
              handleClick={handleSelectGeneralPaymentMethod}
              isSelected={selectedGeneralPaymentMethod?.id === paymentMethod.id}
            />
          ))}
        </div>
      </div>
      <div
        id="payment-method-list"
        className="w-full flex flex-col justify-between lg:w-1/2 lg:border-l-2 lg:border-l-amber-300"
      >
        <BaseDetailsSelect
          subscriptionCards={subscriptionCards}
          subscriptionCardsLoading={subscriptionCardsLoading}
          selectedCard={selectedCard}
          selectedGeneralPaymentMethod={selectedGeneralPaymentMethod}
          selectedDetailPaymentMethod={selectedDetailPaymentMethod}
          detailsPaymentMethods={detailsPaymentMethods}
          handleSelectDetailPaymentMethod={handleSelectDetailPaymentMethod}
          handleSelectSubscriptionCard={handleSelectSubscriptionCard}
          handleTryDeleteSubscription={handleTryDeleteSubscription}
        />
        <form
          action={`${PORTAL_URL}/payments/pay${metricsToken ? `?metrics_token=${metricsToken}` : ''}`}
          method="POST"
          className="mb-8 lg:mb-0"
          onSubmit={handleSubmit}
        >
          <input readOnly hidden name="payment_id" value={id} />
          <input
            readOnly
            hidden
            name="payment_method_id"
            value={selectedDetailPaymentMethod?.name}
          />
          <Button
            text={
              loadingPayButton || loadingSubmit ? (
                <svg
                  className="animate-spin mx-5 h-7 w-7 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
              ) : (
                'Pagar'
              )
            }
            value=""
            type={
              isSubscriptionPaymentMethod(
                selectedDetailPaymentMethod?.handler as string
              )
                ? undefined
                : 'submit'
            }
            onClick={handlePayClick}
            disabled={!canPay || loadingPayButton || loadingSubmit}
          />
        </form>
      </div>
    </div>
  );
};

export default PaymentMethodSelector;
