import { CLOUDFRONT_URL } from '../../utils/urls';

const logoDict: { [key: string]: string } = {
  'Fintoc::V1::Plugin': `${CLOUDFRONT_URL}/payment-gateway/t1/logo-fintoc.svg`,
  'Fintoc::Subscriptions::V1::Plugin': `${CLOUDFRONT_URL}/payment-gateway/t1/logo-fintoc.svg`,
  'Khipu::V1::Plugin': `${CLOUDFRONT_URL}/payment-gateway/t1/logo-khipu.png`,
  'TbkProxy::Webpay': `${CLOUDFRONT_URL}/payment-gateway/t1/logo-webpay.png`,
  'Bank::Bsan::V1::Plugin': `${CLOUDFRONT_URL}/payment-gateway/t1/logo-santander.png`,
  'Bank::Bci::V1::Plugin': `${CLOUDFRONT_URL}/payment-gateway/t1/logo-bci.png`,
  'TbkProxy::OneClick': `${CLOUDFRONT_URL}/payment-gateway/t1/logo-webpay_oneclick.png`,
  'TbkProxy::OneClickMall': `${CLOUDFRONT_URL}/payment-gateway/t1/logo-webpay_oneclick.png`,
  'Bank::Bice::V1::Plugin': `${CLOUDFRONT_URL}/payment-gateway/t1/logo-bice.png`,
  'Bank::Bchile::V1::Plugin': `${CLOUDFRONT_URL}/payment-gateway/t1/logo-bancochile.png`,
  'Bank::Scotia::V1::Plugin': `${CLOUDFRONT_URL}/payment-gateway/t1/logo-scotia.png`,
  'Bank::Itau::V1::Plugin': `${CLOUDFRONT_URL}/payment-gateway/t1/logo-itau.png`,
  'TbkProxy::Pos': `${CLOUDFRONT_URL}/payment-gateway/t1/logo-tbk-pos.png`,
};

const cardLogoDict: { [key: string]: string } = {
  RedCompra: `${CLOUDFRONT_URL}/payment-gateway/t1/cards/redcompra.png`,
  AmericanExpress: `${CLOUDFRONT_URL}/payment-gateway/t1/cards/americanexpress.png`,
  Visa: `${CLOUDFRONT_URL}/payment-gateway/t1/cards/visa.png`,
  MasterCard: `${CLOUDFRONT_URL}/payment-gateway/t1/cards/mastercard.png`,
};

const paymentMethodLogoSearch = (handler: string) => {
  if (!(handler in logoDict)) {
    return '';
  }
  return logoDict[handler];
};

const subscriptionCardLogoSearch = (cardType: string) => {
  if (!(cardType in cardLogoDict)) {
    return `${CLOUDFRONT_URL}/payment-gateway/t1/cards/default.png`;
  }
  return cardLogoDict[cardType];
};

const logoSearcher = (key: string, isCard: boolean) => {
  if (isCard) {
    return subscriptionCardLogoSearch(key);
  }
  return paymentMethodLogoSearch(key);
};

export default logoSearcher;
