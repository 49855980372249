import { ChangeEvent, FC, memo, useState } from 'react';
import useColors from '../../../hooks/useColors';

interface CustomTextAreaProps {
  required?: boolean;
  id: string;
  type?: string;
  placeholder: string;
  name: string;
  value: string | number | undefined;
  disabled?: boolean;
  handleChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  autoComplete?: 'on' | 'off';
}

const CustomTextArea: FC<CustomTextAreaProps> = (props) => {
  const { primaryColor } = useColors();
  const [isFocus, setIsFocus] = useState<boolean>(false);

  return (
    <textarea
      {...props}
      onChange={props.handleChange ? props.handleChange : () => null}
      onFocus={() => setIsFocus(true)}
      onBlur={() => setIsFocus(false)}
      className="bg-gray-50 border text-gray-900 text-sm h-11 rounded-lg block w-full p-2.5 disabled:bg-[#D2D2D2]"
      style={{
        borderColor: isFocus ? primaryColor : '#D2D2D2',
        outlineColor: isFocus ? primaryColor : 'transparent',
      }}
    />
  );
};

export default memo(CustomTextArea);
