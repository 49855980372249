import { FC } from 'react';

// middlewares
import {
  OpenParserOutputData,
  SubscriptionInputType,
} from '../../../../middlewares/Open/openTypes';

// hooks
import useColors from '../../../../hooks/useColors';

// components
import PersonalInformation from './PersonalInformation';
import SubscriptionInformation from './SubscriptionInformation';
import TermsAndConditions from './TermsAndConditions';
import Button from '../../../shared/Button';
import isEmail from '../../../../utils/isEmail';

interface BaseSubscriptionFieldsProps {
  apiData: OpenParserOutputData;
  subscriptionInputs: SubscriptionInputType;
  handleChangeInput: (
    name: keyof SubscriptionInputType,
    value: string | number | boolean,
    type: string
  ) => void;
  handleSubscriptionSubmit: () => void;
}

const BaseSubscriptionFields: FC<BaseSubscriptionFieldsProps> = (props) => {
  const {
    apiData,
    subscriptionInputs,
    handleChangeInput,
    handleSubscriptionSubmit,
  } = props;
  const { secondaryColor } = useColors();
  const isOpen =
    apiData.paymentRequest.blankAmount ||
    !apiData.paymentRequest.name ||
    !apiData.paymentRequest.email;

  return (
    <div className="w-full h-full mb-5 lg:mb-0">
      <div>
        <h1 className="text-4xl font-medium" style={{ color: secondaryColor }}>
          {isOpen ? 'Información' : 'Confirmar información'}
        </h1>
      </div>
      <div className="flex flex-col lg:flex-row my-5">
        <PersonalInformation
          paymentRequestData={apiData.paymentRequest}
          subscriptionInputs={subscriptionInputs}
          handleChangeInput={handleChangeInput}
        />
        <SubscriptionInformation
          paymentRequestData={apiData.paymentRequest}
          subscriptionInputs={subscriptionInputs}
          handleChangeInput={handleChangeInput}
        />
      </div>
      {!isOpen && (
        <TermsAndConditions
          subscriptionInputs={subscriptionInputs}
          handleChangeInput={handleChangeInput}
        />
      )}
      <Button
        text={isOpen ? 'Enviar' : 'Confirmar'}
        onClick={handleSubscriptionSubmit}
        value=""
        disabled={
          !(
            (isOpen || subscriptionInputs.termsAndConditions === true) &&
            subscriptionInputs.name !== '' &&
            isEmail(subscriptionInputs.email) &&
            subscriptionInputs.amount !== '' &&
            (subscriptionInputs.amount as number) > 0
          )
        }
      />
    </div>
  );
};

export default BaseSubscriptionFields;
