import { FC } from 'react';
import { SubscriptionCardOutputData } from '../../../../middlewares/Payments/types';
import Modal from '../../../common/Modal';
import Button from '../../../shared/Button';

interface DeleteSubscriptionCardModalProps {
  subscriptionCard: SubscriptionCardOutputData;
  closeModal: () => void;
  handleDelete: () => void;
}

const DeleteSubscriptionCardModal: FC<DeleteSubscriptionCardModalProps> = (
  props
) => {
  const { subscriptionCard, closeModal, handleDelete } = props;

  return (
    <Modal title="Eliminar suscripción" closeModal={closeModal}>
      <div className="px-5 pb-5">
        <div>
          <p>¿Seguro que desea eliminar la suscripción con su tarjeta?</p>

          <div className="flex w-full justify-evenly my-5">
            <div className="flex">
              <img
                src={subscriptionCard.cardLogo}
                className="my-auto max-h-12 max-w-32"
              />
            </div>
            <div className="text-sm">
              <p>xxxx-{subscriptionCard.cardDigits}</p>
              <p>{subscriptionCard.name}</p>
              <p>{subscriptionCard.email}</p>
            </div>
          </div>

          <Button text="Eliminar" onClick={handleDelete} value="delete" />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteSubscriptionCardModal;
