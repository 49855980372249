import { FC, memo } from 'react';

// hooks
import useColors from '../../../../hooks/useColors';

// utils
import { Nullable } from '../../../../utils/customTypes';

interface SubscriptionHeaderProps {
  merchantLogo: Nullable<string>;
  merchantName: string;
}

const SubscriptionHeader: FC<SubscriptionHeaderProps> = (props) => {
  const { merchantLogo, merchantName } = props;
  const { secondaryColor } = useColors();

  return (
    <div className="h-1/6 w-full flex flex-col lg:flex-row justify-between py-3 mt-5 lg:mt-0 lg:py-7">
      <div className="h-full lg:mt-0 w-full flex flex-col lg:flex-row">
        <div className="mx-auto mt-auto w-full lg:w-auto lg:mx-0">
          <h1 className="text-3xl font-roboto font-medium mr-4">
            Te estás suscribiendo en:
          </h1>
        </div>
        <div
          className={`mx-auto mt-5 w-full lg:mt-auto lg:w-auto lg:mx-0 h-full flex ${merchantLogo ? 'items-end' : 'items-center'}`}
        >
          {merchantLogo ? (
            <img
              src={merchantLogo}
              className="max-h-16 lg:max-h-10 max-w-80 mx-auto lg:mx-0 object-contain"
              alt="logo"
            />
          ) : (
            <h1
              className="text-4xl font-roboto font-bold mx-auto lg:mx-0"
              style={{ color: secondaryColor }}
            >
              {merchantName || ''}
            </h1>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(SubscriptionHeader);
