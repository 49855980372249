import {
  LinkConfigurationInputData,
  LinkConfigurationsOutputData,
  MerchantInputData,
  MerchantOutputData,
  OpenParserInputData,
  OpenParserOutputData,
  PaymentRequestInputData,
  PaymentRequestOutputData,
  TemplateInputType,
  TemplateOutputType,
} from './openTypes';

const parseMerchant: (merchantData: MerchantInputData) => MerchantOutputData = (
  merchantData
) => {
  const { id, name, logo, colors, preferred_template } = merchantData;
  const parsedMerchant = {
    id,
    name,
    logo,
    preferredTemplate: preferred_template,
    primaryColor: null,
    secondaryColor: null,
  };
  if (colors !== null) {
    const parsedColors = JSON.parse(colors);
    return {
      ...parsedMerchant,
      primaryColor: parsedColors.primaryColor,
      secondaryColor: parsedColors.secondaryColor,
    };
  }
  return parsedMerchant;
};

const parseLinkConfiguration: (
  linkConfigurationData: LinkConfigurationInputData
) => LinkConfigurationsOutputData = (linkConfigurationData) => {
  const {
    id,
    custom_field_type: customFieldType,
    position,
    hidden,
    label,
    dismissable,
    value,
    name,
    placeholder,
    validations,
    data,
  } = linkConfigurationData;

  return {
    id,
    customFieldType,
    position,
    hidden,
    label,
    dismissable,
    value,
    name,
    placeholder,
    validations,
    data: data ? JSON.parse(data) : null,
  };
};

const parsePaymentRequest: (
  paymentRequestData: PaymentRequestInputData
) => PaymentRequestOutputData = (paymentRequestData) => {
  const {
    id,
    identifier,
    name,
    email,
    amount,
    currency,
    blank_amount: blankAmount,
    has_iva: hasIva,
    has_link_configurations: hasLinkConfigurations,
    link_configurations: linkConfigurationsData,
  } = paymentRequestData;

  return {
    id,
    identifier,
    name,
    email,
    amount,
    currency,
    blankAmount,
    hasIva,
    hasLinkConfigurations,
    linkConfigurations: linkConfigurationsData
      .map((linkConfigurationData) =>
        parseLinkConfiguration(linkConfigurationData)
      )
      .sort((a, b) => a.position - b.position),
  };
};

export const templateParser: (data: TemplateInputType) => TemplateOutputType = (
  data: TemplateInputType
) => {
  const {
    data: { merchant: merchantData, valid },
  } = data;

  return {
    merchant: parseMerchant(merchantData),
    valid,
  };
};

const openParser: (data: OpenParserInputData) => OpenParserOutputData = (
  data
) => {
  const {
    data: {
      merchant: merchantData,
      payment_request: paymentRequestData,
      subscription,
      generated_link: generatedLink,
      metrics_token: metricsToken,
      storable,
    },
  } = data;

  const parsedData = {
    merchant: parseMerchant(merchantData),
    paymentRequest: parsePaymentRequest(paymentRequestData),
    isSubscription: subscription,
    generatedLink,
    metricsToken,
    storable,
  };

  return parsedData;
};

export default openParser;
