import { FC } from 'react';
import { SubscriptionOutputData } from '../../../../middlewares/Return/returnTypes';
import useColors from '../../../../hooks/useColors';

interface SubscriptionStatusProps {
  subscription: SubscriptionOutputData;
}

const SubscriptionStatus: FC<SubscriptionStatusProps> = (props) => {
  const { subscription } = props;
  const { secondaryColor } = useColors();
  return (
    <div className="w-full my-auto py-4 px-8 md:w-5/12">
      <h1 className="text-lg font-bold mb-4" style={{ color: secondaryColor }}>
        Estado de la suscripción:
      </h1>
      <div className="text-sm">
        {subscription.id && (
          <div className="font-medium">
            <p>Te has suscrito correctamente</p>
            <p className="payment_date">{subscription.message}</p>
            <p className="text-xs">
              Modo de revocación: Comunicación con el comercio por email
            </p>
          </div>
        )}
        {!subscription.id && subscription.missing && (
          <p className="font-medium">
            Hubo un problema en el registro de la tarjeta.
          </p>
        )}
        {!subscription.id && !subscription.missing && (
          <p className="font-medium">
            Tu tarjeta ya había sido suscrita anteriormente
          </p>
        )}
      </div>
    </div>
  );
};

export default SubscriptionStatus;
