import { ChangeEvent, FC, FormEvent, useEffect, useState } from 'react';

// midllewares
import {
  OpenInputType,
  PaymentRequestOutputData,
} from '../../../../middlewares/Open/openTypes';

// hooks
import useColors from '../../../../hooks/useColors';

// components
import CustomInput from '../../common/CustomInput';
import Button from '../../../shared/Button';
import DescriptionExtensionField from '../Fields/DescriptionExtensionField';
import DescriptionField from '../Fields/DescriptionField';
import RemoteTextField from '../Fields/RemoteTextField';
import TextField from '../Fields/TextField';

// utils
import isEmail from '../../../../utils/isEmail';
import allowedValidations from '../../../../utils/templateConfigurations/open/allowedValidations';

// icons
import { ReactComponent as AlertIcon } from '../../../../assets/feather/alert-octagon.svg';

interface BaseFieldsProps {
  paymentRequest: PaymentRequestOutputData;
  onChangeInput: (name: string, value: string | number) => void;
  inputs: OpenInputType;
  dismissedFields: { [key: string]: boolean };
  onDismissField: (name: string, dismiss: boolean) => void;
  handleSubmit: () => void;
  storeInputsOnLocal: () => void;
  storable: boolean;
  recheckValidations: boolean;
  setRecheckValidations: React.Dispatch<React.SetStateAction<boolean>>;
}

const BaseFields: FC<BaseFieldsProps> = (props) => {
  const {
    paymentRequest,
    inputs,
    onChangeInput,
    dismissedFields,
    onDismissField,
    handleSubmit,
    storeInputsOnLocal,
    storable,
    recheckValidations,
    setRecheckValidations,
  } = props;
  const { primaryColor, secondaryColor } = useColors();
  const [errors, setErrors] = useState<{ [key: string]: null | string }>(
    paymentRequest.linkConfigurations
      .map((lc) => lc.name)
      .reduce((prev, curr) => ({ ...prev, [curr]: null }), {})
  );
  const [storeInputsCheck, setStoreInputsCheck] = useState<boolean>(false);
  const inputsToValidate = paymentRequest.linkConfigurations
    .filter((lc) => {
      const fieldValidations =
        allowedValidations[
          (lc.customFieldType || '') as keyof typeof allowedValidations
        ] || {};
      const validations = Object.keys(fieldValidations).filter(
        (validation) => validation in (lc.data || {})
      );
      return validations.length > 0;
    })
    .map((lc) => lc.name);

  useEffect(() => {
    if (!recheckValidations) return;
    Object.entries(inputs).forEach((val) =>
      validateField(val[1] as string, val[0])
    );

    setRecheckValidations(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recheckValidations]);

  const validateField = (value: string, name: string) => {
    let parsedValue = value;
    let hasError = false;
    if (!inputsToValidate.includes(name)) {
      return parsedValue;
    }
    const lc = paymentRequest.linkConfigurations.filter(
      (linkConf) => linkConf.name === name
    )[0];

    const fieldValidators =
      allowedValidations[lc.customFieldType as keyof typeof allowedValidations];

    for (const validator in fieldValidators) {
      if ((lc.data || {})[validator as keyof typeof lc.data]) {
        [parsedValue, hasError] =
          fieldValidators[validator].validator(parsedValue);
      }
      setErrors((values) => ({
        ...values,
        [name]: hasError ? fieldValidators[validator].message : null,
      }));
    }
    return parsedValue;
  };

  const handleChange = (e: ChangeEvent<HTMLFormElement>) => {
    const { name, value, type } = e.target;
    if (type === 'number') {
      onChangeInput(name, parseInt(value as string));
    }
    onChangeInput(name, validateField(value, name));
  };

  const handleRemoteChange = (name: string, value: string | number) => {
    onChangeInput(name, validateField(`${value}`, name));
  };

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (storable && storeInputsCheck) storeInputsOnLocal();
    handleSubmit();
  };

  const handleCheck = () => {
    if (!storable) return;
    setStoreInputsCheck(!storeInputsCheck);
  };

  return (
    <div className="w-full h-full">
      <form
        onChange={handleChange}
        onSubmit={handleFormSubmit}
        className="h-full flex flex-col"
      >
        <div
          id="fields-div"
          className="w-full grid gap-4 lg:gap-x-10 gap-y-4 mb-6 lg:grid-cols-2"
        >
          <div className="mx-auto w-11/12 text-left">
            <label
              htmlFor="name"
              className="text-sm font-semibold w-full mr-auto"
              style={{ color: secondaryColor }}
            >
              Nombre:
            </label>
            <CustomInput
              id="name"
              name="name"
              placeholder="Ingrese su nombre"
              value={inputs.name}
              disabled={paymentRequest.name !== ''}
            />
          </div>
          <div className="mx-auto w-11/12 text-left">
            <label
              htmlFor="email"
              className="text-sm font-semibold w-full mr-auto"
              style={{ color: secondaryColor }}
            >
              Email:
            </label>
            <CustomInput
              required
              id="email"
              name="email"
              placeholder="Ingrese su email"
              value={inputs.email}
            />
            {inputs.email && !isEmail(inputs.email) && (
              <div className="flex text-red-500">
                <AlertIcon className="h-4 my-auto" />
                <p className="text-sm">El mail indicado no es válido</p>
              </div>
            )}
          </div>
          <div className="mx-auto w-11/12 text-left">
            <label
              htmlFor="amount"
              className="text-sm font-semibold w-full mr-auto"
              style={{ color: secondaryColor }}
            >
              Monto a pagar:
            </label>
            <CustomInput
              type="number"
              id="amount"
              name="amount"
              placeholder="Ingrese el monto"
              autoComplete="off"
              value={inputs.amount}
              min={1}
              max={100000000000}
              disabled={
                paymentRequest.amount !== 0 && paymentRequest.amount !== null
              }
              icon={<p>$</p>}
              currency={
                paymentRequest.currency ? (
                  <p>{paymentRequest.currency?.toUpperCase()}</p>
                ) : null
              }
            />
            {inputs.amount && (inputs.amount as number) <= 0 && (
              <div className="flex text-red-500">
                <AlertIcon className="h-4 my-auto" />
                <p className="text-sm">El monto debe ser mayor a 0</p>
              </div>
            )}
          </div>
          {paymentRequest.linkConfigurations.map((lc) => {
            const fieldTypeDict = {
              'LinkConfigurations::Text': (
                <TextField
                  key={lc.id}
                  inputs={inputs}
                  linkConfiguration={lc}
                  dismissed={dismissedFields[lc.name]}
                  onDismissField={onDismissField}
                  error={errors[lc.name]}
                />
              ),
              'LinkConfigurations::RemoteText': (
                <RemoteTextField
                  key={lc.id}
                  inputs={inputs}
                  onChangeInput={handleRemoteChange}
                  linkConfiguration={lc}
                  dismissed={dismissedFields[lc.name]}
                  onDismissField={onDismissField}
                  error={errors[lc.name]}
                />
              ),
              'LinkConfigurations::Description': (
                <DescriptionField
                  key={lc.id}
                  inputs={inputs}
                  linkConfiguration={lc}
                  dismissed={dismissedFields[lc.name]}
                  onDismissField={onDismissField}
                />
              ),
              'LinkConfigurations::DescriptionExtensionField': (
                <DescriptionExtensionField
                  key={lc.id}
                  inputs={inputs}
                  linkConfiguration={lc}
                  dismissed={dismissedFields[lc.name]}
                  onDismissField={onDismissField}
                />
              ),
            };

            if (lc.customFieldType in fieldTypeDict) {
              return fieldTypeDict[
                lc.customFieldType as keyof typeof fieldTypeDict
              ];
            }
            return (
              <TextField
                key={lc.id}
                inputs={inputs}
                linkConfiguration={lc}
                dismissed={dismissedFields[lc.name]}
                onDismissField={onDismissField}
                error={errors[lc.name]}
              />
            );
          })}
        </div>
        <div
          id="button-div"
          className="mt-auto w-full flex flex-col justify-center items-center lg:items-end lg:mb-0 lg:w-auto lg:pr-4 lg:justify-end"
        >
          {storable && (
            <div className="flex mb-5 items-center">
              <div className="flex items-center h-5">
                <input
                  id="helper-checkbox"
                  aria-describedby="helper-checkbox-text"
                  type="checkbox"
                  onClick={handleCheck}
                  checked={storeInputsCheck}
                  value=""
                  className="w-4 h-4 bg-gray-100 border-gray-300 rounded"
                  style={{
                    accentColor: primaryColor,
                  }}
                />
              </div>
              <div className="ms-2 text-sm">
                <label
                  htmlFor="helper-checkbox"
                  className="font-semibold text-md text-gray-900"
                  style={{ userSelect: 'none' }}
                >
                  Guardar datos para la próxima vez
                </label>
              </div>
            </div>
          )}
          <Button
            type="submit"
            text="Pagar"
            value="pay"
            disabled={
              !(
                inputs.name !== '' &&
                isEmail(inputs.email) &&
                inputs.amount !== '' &&
                (inputs.amount as number) > 0 &&
                paymentRequest.linkConfigurations.reduce(
                  (prev, curr) =>
                    inputs[curr.name] !== '' &&
                    errors[curr.name] === null &&
                    prev,
                  true
                )
              )
            }
            onClick={() => null}
          />
        </div>
      </form>
    </div>
  );
};

export default BaseFields;
