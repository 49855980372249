import { useEffect, useState } from 'react';
import { LINKER_URL } from '../utils/urls';
import axios from 'axios';

type Nullable<T> = T | null;

interface UseLinkerFetchResult<T> {
  apiData: Nullable<T>;
  loading: boolean;
  error: boolean | string;
  refetch: () => void;
}

const useLinkerFetch = <T>(
  endpoint: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parseFunction: (data: any) => T
): UseLinkerFetchResult<T> => {
  const [apiData, setApiData] = useState<Nullable<T>>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean | string>(false);
  const [refetchIndex, setRefetchIndex] = useState<number>(0);

  const refetch: () => void = () =>
    setRefetchIndex((prevRefetchIndex) => prevRefetchIndex + 1);

  useEffect(() => {
    const getData = async () => {
      try {
        setError(false);
        setLoading(true);

        const url = `${LINKER_URL}/${endpoint}`;

        const headers = {
          'Content-Type': 'application/json',
        };

        const res = await axios.get(url, {
          headers,
        });

        if (res.status === 200) {
          const { data } = res;
          const parsedData = parseFunction(data);
          setApiData(parsedData);
        } else {
          setError(true);
        }
      } catch (err) {
        console.log(err);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint, refetchIndex]);

  return {
    apiData,
    loading,
    error,
    refetch,
  };
};

export default useLinkerFetch;
