import { OpenParserInputData } from '../openTypes';

const subscriptionTemplateData: OpenParserInputData = {
  status: 200,
  data: {
    generated_link: 'http://www.example.com',
    subscription: true,
    metrics_token: '0',
    storable: false,
    merchant: {
      id: 1,
      name: 'Prueba',
      logo: null,
      colors: '{}',
      preferred_template: 0,
    },
    payment_request: {
      id: 'aaa',
      identifier: 'example-identifier',
      name: 'John Doe',
      email: 'example@mail.com',
      amount: 1000000,
      currency: null,
      blank_amount: false,
      has_iva: false,
      has_link_configurations: true,
      link_configurations: [],
    },
  },
};

export default subscriptionTemplateData;
