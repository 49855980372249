import $ from 'jquery';

interface PostToRedirectionJson {
  data: {
    response: {
      url: string;
      id: string;
      token: string;
    };
    url: string;
    token: string;
  };
}

const postToRedirection: (json: PostToRedirectionJson) => void = (json) => {
  console.log('postToRedirection json:', json);
  const body = json.data.response;
  console.log('.... body: ', body);
  if (body.url && body.token) {
    // const url = body.url + '?TBK_TOKEN=' + body.token;
    const url = body.url + '?TBK_TOKEN=' + body.token;
    const f = $("<form action='" + url + "' method='POST'>");
    const i = $("<input name='token_ws' value='" + body.token + "'>");
    f.append(i);
    $('body').append(f);
    console.log('url: ', url, '..... -> ', f.html());
    f.trigger('submit');
  }
};

export default postToRedirection;
