import { FC } from 'react';

import './../../../styles/layout.css';
import Footer from './Footer';

interface CardProps {
  children: React.ReactNode;
  shape?: string;
}

const Layout: FC<CardProps> = (props) => {
  const shape: { [key: string]: string } = {
    smaller: 'smaller-card',
    normal: 'normal-card',
    bigger: 'bigger-card',
  };

  return (
    <div className="min-h-[100vh] w-full flex flex-col overflow-auto">
      <div
        className={`card m-auto flex flex-1 w-full ${props.shape && props.shape in shape ? shape[props.shape] : 'normal-card'} rounded-lg overflow-hidden shadow-xl p-0 lg:p-[60px]`}
      >
        {props.children}
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
