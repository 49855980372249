import { ChangeEvent, FC, useState } from 'react';
import Modal from '../../common/Modal';
import useColors from '../../../hooks/useColors';
import Button from '../../shared/Button';

interface ChangeColorModalProps {
  closeModal: (open: boolean) => void;
}

const ChangeColorModal: FC<ChangeColorModalProps> = (props) => {
  const { closeModal } = props;
  const { primaryColor, secondaryColor, savePrimaryColor, saveSecondaryColor } =
    useColors();
  const [inputs, setInputs] = useState<{
    primaryColor: string;
    secondaryColor: string;
  }>({
    primaryColor,
    secondaryColor,
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInputs((values) => ({
      ...values,
      [name]: value.toUpperCase(),
    }));
  };

  const handleClick = () => {
    if (inputs.primaryColor) {
      savePrimaryColor(inputs.primaryColor);
    }
    if (inputs.secondaryColor) {
      saveSecondaryColor(inputs.secondaryColor);
    }
    closeModal(false);
  };

  return (
    <Modal
      title="Probar otros colores en el template"
      closeModal={() => closeModal(false)}
    >
      <p className="flex-wrap text-sm text-justify text-gray-500 flex max-w-xl px-10 mb-4">
        La vista contempla dos colores principales encontrados en la barra
        inferior y que pueden ser elegidos por el comercio. Estos realizan
        pequeños cambios en textos y botones que puedes probar usando estos
        campos. Para hacer estos cambios permanentes debe modificarlos desde la
        plataforma Web:
      </p>
      <div className="px-14 pb-5">
        <div className="w-full flex justify-between align-middle mb-2">
          <label className="my-auto text-center">
            Color primario: {inputs.primaryColor}
          </label>
          <input
            id="primaryColor"
            name="primaryColor"
            type="color"
            className="my-auto h-12"
            value={inputs.primaryColor}
            onChange={handleChange}
          />
        </div>
        <div className="w-full flex justify-between align-middle mt-2">
          <label className="my-auto text-center">
            Color secundario: {inputs.secondaryColor}
          </label>
          <input
            id="secondaryColor"
            name="secondaryColor"
            type="color"
            className="my-auto h-12"
            value={inputs.secondaryColor}
            onChange={handleChange}
          />
        </div>
        <div className="mt-5">
          <Button text="Probar colores" onClick={handleClick} value="color" />
        </div>
      </div>
    </Modal>
  );
};

export default ChangeColorModal;
