import { FC, useEffect } from 'react';

interface RedirectProps {
  url: string;
}

const Redirect: FC<RedirectProps> = (props) => {
  const { url } = props;

  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return <h5>Returning to PagoHub...</h5>;
};

export default Redirect;
