import React, { Context, FC, useState } from 'react';
import colorsParser from '../utils/colorsParser';

interface UseProvideColorsProps {
  children: React.ReactNode;
}

interface ColorsContextValues {
  primaryColor: string;
  secondaryColor: string;
  savePrimaryColor: (color: string) => void;
  saveSecondaryColor: (color: string) => void;
}

export const ColorsContext: Context<ColorsContextValues> = React.createContext({
  primaryColor: '#298EC5',
  secondaryColor: '#1B1464',
  savePrimaryColor: (color) => console.log(color),
  saveSecondaryColor: (color) => console.log(color),
});

const useProvideColors = () => {
  const [primaryColor, setPrimaryColor] = useState('#298EC5');
  const [secondaryColor, setSecondaryColor] = useState('#1B1464');

  const savePrimaryColor: (color: string) => void = (color: string) => {
    setPrimaryColor(colorsParser(color, 'primary'));
  };
  const saveSecondaryColor: (color: string) => void = (color: string) => {
    setSecondaryColor(colorsParser(color, 'secondary'));
  };

  return {
    primaryColor,
    savePrimaryColor,
    secondaryColor,
    saveSecondaryColor,
  };
};

const ColorsProvider: FC<UseProvideColorsProps> = (props) => {
  const { children } = { ...props };
  const { primaryColor, secondaryColor, savePrimaryColor, saveSecondaryColor } =
    useProvideColors();
  return (
    <ColorsContext.Provider
      value={{
        primaryColor,
        secondaryColor,
        savePrimaryColor,
        saveSecondaryColor,
      }}
    >
      {children}
    </ColorsContext.Provider>
  );
};

export default ColorsProvider;
