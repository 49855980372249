import axios from 'axios';
import { PORTAL_URL } from '../urls';

const sendMetrics: (metricsToken: string) => void = async (
  metricsToken: string
) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  const url = `${PORTAL_URL}/payments/metrics`;
  const body = {
    metrics_token: metricsToken,
  };
  try {
    const res = await axios.put(url, body, {
      headers,
    });
    if (res.status === 204) {
      return true;
    }
    return false;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export default sendMetrics;
