import { CLOUDFRONT_URL } from '../../utils/urls';
import { GeneralPayments } from './types';

const generalPayments: GeneralPayments = {
  fintoc: {
    slug: '1',
    logo: `${CLOUDFRONT_URL}/payment-gateway/t1/logo-fintoc_horizontal.svg`,
    title: 'Paga con tu banco usando Fintoc',
    description: 'Pague con su banco usando la aplicación Fintoc',
    id: '1',
    name: 'fintoc-default',
    maximum: '',
    minPrice: 0,
    maxPrice: 0,
    position: 0,
    handler: 'fintoc',
  },
  oneClick: {
    slug: '2',
    logo: `${CLOUDFRONT_URL}/payment-gateway/t1/logo-webpay_oneclick_horizontal.png`,
    title: 'WebPay OneClick',
    description: 'Paga directo con tu tarjeta',
    id: '2',
    name: 'oneclick-default',
    maximum: '',
    minPrice: 0,
    maxPrice: 0,
    position: 0,
    handler: 'oneClick',
  },
  webPayDeb: {
    slug: '3',
    logo: `${CLOUDFRONT_URL}/payment-gateway/t1/logo-redcompra.png`,
    title: 'Paga con débito',
    description: 'Paga a través de WebPay',
    id: '3',
    name: 'webpay-default',
    maximum: '',
    minPrice: 0,
    maxPrice: 0,
    position: 0,
    handler: 'webPay',
  },
  khipu: {
    slug: '4',
    logo: `${CLOUDFRONT_URL}/payment-gateway/t1/logo-khipu.svg`,
    title: 'Paga con tu banco usando Khipu',
    description: 'Pague con su banco usando la aplicación Khipu',
    id: '4',
    name: 'khipu-default',
    maximum: '',
    minPrice: 0,
    maxPrice: 0,
    position: 0,
    handler: 'khipu',
  },
  webPayCred: {
    slug: '5',
    logo: `${CLOUDFRONT_URL}/payment-gateway/t1/logo-credito.png`,
    title: 'Paga con tarjeta de crédito',
    description: 'Paga a través de WebPay',
    id: '5',
    name: 'webpay-default',
    maximum: '',
    minPrice: 0,
    maxPrice: 0,
    position: 0,
    handler: 'webPay',
  },
};

export default generalPayments;
