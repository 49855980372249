import { FC, useState } from 'react';
import textWrapper from '../../../../utils/textWrapper';
import useColors from '../../../../hooks/useColors';
import logoParser from '../../../../utils/logoParser';

interface PaymentMethodSelectProps {
  paymentMethodId: string;
  paymentMethodLogoURL: string;
  paymentMethodTitle: string;
  handleClick: (paymentMethodSlug: string) => void;
  isSelected: boolean;
}

const PaymentMethodSelect: FC<PaymentMethodSelectProps> = (props) => {
  const {
    paymentMethodLogoURL,
    paymentMethodTitle,
    paymentMethodId,
    handleClick,
    isSelected,
  } = props;
  const { primaryColor } = useColors();
  const [isHover, setIsHover] = useState(false);

  const handlePaymentMethodClick: (slug: string) => void = (slug: string) => {
    handleClick(slug);
  };
  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <div>
      <input
        id={`checkbox-general-${paymentMethodId}`}
        className="hidden peer"
        type="checkbox"
        checked={isSelected}
        value=""
        style={{ accentColor: primaryColor }}
        onChange={() => handlePaymentMethodClick(paymentMethodId)}
      />
      <label
        htmlFor={`checkbox-general-${paymentMethodId}`}
        className="
          min-w-full min-h-14 border-[1px] shadow-md mb-2 
          flex flex-row justify-between rounded-lg cursor-pointer 
          hover:border-2 space-x-1 px-4 lg:px-7
        "
        style={{
          borderColor: isHover || isSelected ? primaryColor : '#cac9c9',
          borderWidth: isHover || isSelected ? '3px' : '1px',
          color: isSelected ? primaryColor : 'black',
          backgroundColor: isHover ? '#F8F8F8' : 'white',
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <h1 className="md:w-1/2 text-xs md:text-md font-semibold text-left my-auto cursor-pointer">
          {textWrapper(paymentMethodTitle, 47)}
        </h1>
        <div className="md:w-1/2 my-auto mr-2 text-left cursor-pointer select-none">
          <img
            className="my-auto max-h-8 md:max-h-6"
            src={logoParser(paymentMethodLogoURL)}
          />
        </div>
      </label>
    </div>
  );
};

export default PaymentMethodSelect;
