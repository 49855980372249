import generalPayments from './generalPayments';
import logoSearcher from './logoSearcher';
import {
  GeneralPaymentMethods,
  MerchantInputData,
  MerchantOutputData,
  PaymentMethodInputData,
  PaymentMethodOutputData,
  PaymentsParserInputData,
  PaymentsParserOutputData,
  TemplateInputType,
  TemplateOutputType,
  TransactionInputData,
  TransactionOutputData,
} from './types';

const calculateGeneralPaymentMethodPosition: (
  usedGeneralPaymentMethods: GeneralPaymentMethods,
  type: string
) => number | null = (usedGeneralPaymentMethods, type) => {
  if (!Object.keys(usedGeneralPaymentMethods).includes(type)) {
    return null;
  }

  const paymentMethods =
    usedGeneralPaymentMethods[type as keyof typeof usedGeneralPaymentMethods];
  return paymentMethods.reduce(
    (prev, curr) => (prev > curr.position ? curr.position : prev),
    1000
  );
};

const generalPaymentMethods: (
  paymentMethods: Array<PaymentMethodOutputData>
) => GeneralPaymentMethods = (paymentMethods) => {
  const sortedPaymentMethods: GeneralPaymentMethods = {
    khipu: [],
    fintoc: [],
    webPay: [],
    oneClick: [],
    other: [],
  };
  const showablePaymentMethodsCount = paymentMethods.filter(
    (pm) => pm.position > 0
  ).length;
  paymentMethods.map((pm) => {
    if (showablePaymentMethodsCount > 0 && pm.position === 0) {
      return;
    }
    if (pm.handler.includes('Fintoc::')) {
      sortedPaymentMethods.fintoc = [...sortedPaymentMethods.fintoc, pm];
    } else if (pm.handler.includes('OneClick')) {
      sortedPaymentMethods.oneClick = [...sortedPaymentMethods.oneClick, pm];
    } else if (pm.handler.includes('Khipu::')) {
      sortedPaymentMethods.khipu = [...sortedPaymentMethods.khipu, pm];
    } else if (pm.handler.includes('TbkProxy::Webpay')) {
      sortedPaymentMethods.webPay = [...sortedPaymentMethods.webPay, pm];
    } else {
      sortedPaymentMethods.other = [...sortedPaymentMethods.other, pm];
    }
  });

  return sortedPaymentMethods;
};

const parseTransaction: (
  transactionData: TransactionInputData
) => TransactionOutputData = (transactionData) => {
  const {
    external_transaction_id: externalTransactionId,
    slug,
    amount,
    template,
    return_url: returnUrl,
  } = transactionData;
  return {
    externalTransactionId,
    slug,
    template,
    amount,
    returnUrl,
  };
};

const parseMerchant: (merchantData: MerchantInputData) => MerchantOutputData = (
  merchantData
) => {
  const { id, name, logo, colors } = merchantData;
  const parsedMerchant = {
    id,
    name,
    logo,
    primaryColor: null,
    secondaryColor: null,
  };
  if (colors !== null) {
    const parsedColors = JSON.parse(colors);
    return {
      ...parsedMerchant,
      primaryColor: parsedColors.primaryColor,
      secondaryColor: parsedColors.secondaryColor,
    };
  }
  return parsedMerchant;
};

const parsePaymentMethod: (
  paymentMethodData: PaymentMethodInputData
) => PaymentMethodOutputData = (paymentMethodData) => {
  const {
    id,
    name,
    external_id: slug,
    meta,
    config,
    handler,
  } = paymentMethodData;

  const { title, description } = meta;
  const {
    price_range: { maximum, min: minPrice, max: maxPrice },
    position,
  } = config;

  const logo = logoSearcher(handler, false);

  return {
    id,
    name,
    slug,
    handler,
    logo,
    title,
    description,
    maximum: `${maximum}`,
    maxPrice,
    minPrice,
    position,
  };
};

export const templateParser: (data: TemplateInputType) => TemplateOutputType = (
  data: TemplateInputType
) => {
  const {
    data: {
      merchant: merchantData,
      payment_methods: paymentMethodsData,
      valid,
    },
  } = data;

  const paymentMethods = paymentMethodsData.map((paymentMethodData) =>
    parsePaymentMethod(paymentMethodData)
  );
  const usedGeneralPaymentMethods = generalPaymentMethods(paymentMethods);

  return {
    merchant: parseMerchant(merchantData),
    generalPaymentMethods: [
      ...(usedGeneralPaymentMethods.fintoc.length > 0
        ? [generalPayments.fintoc]
        : []),
      ...(usedGeneralPaymentMethods.webPay.length > 0
        ? [generalPayments.webPayDeb]
        : []),
      ...(usedGeneralPaymentMethods.webPay.length > 0
        ? [generalPayments.webPayCred]
        : []),
      ...(usedGeneralPaymentMethods.oneClick.length > 0
        ? [generalPayments.oneClick]
        : []),
      ...(usedGeneralPaymentMethods.khipu.length > 0
        ? [generalPayments.khipu]
        : []),
      ...usedGeneralPaymentMethods.other,
    ].sort((a, b) => {
      const aPos =
        calculateGeneralPaymentMethodPosition(
          usedGeneralPaymentMethods,
          a.handler
        ) || a.position;
      const bPos =
        calculateGeneralPaymentMethodPosition(
          usedGeneralPaymentMethods,
          b.handler
        ) || b.position;
      return aPos - bPos;
    }),
    paymentMethods: usedGeneralPaymentMethods,
    valid,
  };
};

const paymentsParser: (
  data: PaymentsParserInputData
) => PaymentsParserOutputData = (data) => {
  const {
    data: {
      transaction: transactionData,
      merchant: merchantData,
      payment_methods: paymentMethodsData,
      payment_cancel_path: paymentCancelPath,
      metrics_token: metricsToken,
    },
  } = data;

  const paymentMethods = paymentMethodsData.map((paymentMethodData) =>
    parsePaymentMethod(paymentMethodData)
  );
  const usedGeneralPaymentMethods = generalPaymentMethods(paymentMethods);
  console.log(usedGeneralPaymentMethods);

  const parsedData = {
    transaction: parseTransaction(transactionData),
    merchant: parseMerchant(merchantData),
    generalPaymentMethods: [
      ...(usedGeneralPaymentMethods.fintoc.length > 0
        ? [generalPayments.fintoc]
        : []),
      ...(usedGeneralPaymentMethods.webPay.length > 0
        ? [generalPayments.webPayDeb]
        : []),
      ...(usedGeneralPaymentMethods.webPay.length > 0
        ? [generalPayments.webPayCred]
        : []),
      ...(usedGeneralPaymentMethods.oneClick.length > 0
        ? [generalPayments.oneClick]
        : []),
      ...(usedGeneralPaymentMethods.khipu.length > 0
        ? [generalPayments.khipu]
        : []),
      ...usedGeneralPaymentMethods.other,
    ].sort((a, b) => {
      const aPos =
        calculateGeneralPaymentMethodPosition(
          usedGeneralPaymentMethods,
          a.handler
        ) || a.position;
      const bPos =
        calculateGeneralPaymentMethodPosition(
          usedGeneralPaymentMethods,
          b.handler
        ) || b.position;
      return aPos - bPos;
    }),
    paymentMethods: usedGeneralPaymentMethods,
    paymentCancelPath,
    metricsToken,
  };

  return parsedData;
};

export default paymentsParser;
