import { FC, useState } from 'react';

// middlewaress
import {
  OpenInputType,
  OpenParserOutputData,
  SubscriptionInputType,
} from '../../../../middlewares/Open/openTypes';

// components
import ChangeColorModal from '../../common/ChangeColorModal';
import useIsTemplate from '../../../../hooks/useIsTemplate';
import ColorPickerIcon from '../../../shared/ColorPickerIcon';
import OpenTransactionBaseTemplateOne from './OpenTransactionBaseTemplateOne';
import SubscriptionBaseTemplateOne from './SubscriptionBaseTemplateOne';

// utils
import { Nullable } from '../../../../utils/customTypes';

interface OpenBaseTemplateOneProps {
  apiData: OpenParserOutputData;
  handleChangeOpenInput: (name: string, value: string | number) => void;
  handleChangeSubscriptionInput: (
    name: keyof SubscriptionInputType,
    value: string | number | boolean,
    type: string
  ) => void;
  openInputs: OpenInputType;
  subscriptionInputs: SubscriptionInputType;
  loadedInputs: Nullable<OpenInputType>;
  loadOpenStoredInputs: () => void;
  storeInputsOnLocal: () => void;
  dismissedFields: { [key: string]: boolean };
  onDismissField: (name: string, dismiss: boolean) => void;
  handleOpenSubmit: () => void;
  handleSubscriptionSubmit: () => void;
  recheckValidations: boolean;
  setRecheckValidations: React.Dispatch<React.SetStateAction<boolean>>;
}

const OpenBaseTemplateOne: FC<OpenBaseTemplateOneProps> = (props) => {
  const {
    apiData,
    openInputs,
    subscriptionInputs,
    loadedInputs,
    loadOpenStoredInputs,
    storeInputsOnLocal,
    handleChangeOpenInput,
    handleChangeSubscriptionInput,
    dismissedFields,
    onDismissField,
    handleOpenSubmit,
    handleSubscriptionSubmit,
    recheckValidations,
    setRecheckValidations,
  } = props;
  const { isTemplate } = useIsTemplate();
  const [showColorModal, setShowColorModal] = useState<boolean>(false);

  return (
    <>
      {!apiData.isSubscription && (
        <OpenTransactionBaseTemplateOne
          apiData={apiData}
          inputs={openInputs}
          loadedInputs={loadedInputs}
          loadOpenStoredInputs={loadOpenStoredInputs}
          storeInputsOnLocal={storeInputsOnLocal}
          onChangeInput={handleChangeOpenInput}
          dismissedFields={dismissedFields}
          onDismissField={onDismissField}
          handleSubmit={handleOpenSubmit}
          recheckValidations={recheckValidations}
          setRecheckValidations={setRecheckValidations}
        />
      )}
      {apiData.isSubscription && (
        <SubscriptionBaseTemplateOne
          apiData={apiData}
          subscriptionInputs={subscriptionInputs}
          handleChangeInput={handleChangeSubscriptionInput}
          handleSubscriptionSubmit={handleSubscriptionSubmit}
        />
      )}
      {isTemplate && <ColorPickerIcon openModal={setShowColorModal} />}
      {isTemplate && showColorModal && (
        <ChangeColorModal closeModal={setShowColorModal} />
      )}
    </>
  );
};

export default OpenBaseTemplateOne;
