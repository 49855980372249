import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

// hooks
import useColors from '../hooks/useColors';
import usePortalFetch from '../hooks/usePortalFetch';

// middlewares
import paymentsParser from '../middlewares/Payments/paymentsParser';
import {
  GeneralPaymentMethods,
  PaymentMethodOutputData,
  SubscriptionCardOutputData,
} from '../middlewares/Payments/types';
import subscriptionCardsParser from '../middlewares/Payments/subscriptionCardsParser';

// components
import LoadingScreen from '../components/common/LoadingScreen';
import ErrorScreen from '../components/common/ErrorScreen';
import PaymentBaseTemplateOne from '../components/t1/payments/Base/PaymentsBaseTemplateOne';

// utils
import awaitFunction from '../utils/awaitFunction';
import { Nullable } from '../utils/customTypes';
import isPossibleTemplate from '../utils/isPossibleTemplates';
import isSubscriptionPaymentMethod from '../utils/payments/isSubscriptionPaymentMethod';
import { encondeSubscriptionCard } from '../utils/payments/cipher';
import { PORTAL_URL } from '../utils/urls';

function Payments() {
  const { id } = useParams();
  const [loadingPage, setLoadingPage] = useState<boolean>(true);
  const { savePrimaryColor, saveSecondaryColor } = useColors();
  const { apiData, loading, error } = usePortalFetch(
    `payments/${id}`,
    paymentsParser
  );
  const [canPay, setCanPay] = useState<boolean>(false);
  const [selectedGeneralPaymentMethod, setSelectedGeneralPaymentMethod] =
    useState<Nullable<PaymentMethodOutputData>>(null);
  const [selectedDetailPaymentMethod, setSelectedDetailPaymentMethod] =
    useState<Nullable<PaymentMethodOutputData>>(null);
  const [detailsPaymentMethods, setDetailsPaymentMethods] = useState<
    PaymentMethodOutputData[]
  >([]);
  const [subscriptionCardsLoading, setSubscriptionCardsLoading] =
    useState<boolean>(false);
  const [subscriptionCards, setSubscriptionCards] = useState<
    Array<SubscriptionCardOutputData>
  >([]);
  const [selectedCard, setSelectedCard] =
    useState<Nullable<SubscriptionCardOutputData>>(null);
  const [validTemplate, setValidTemplate] = useState<boolean>(false);

  useEffect(() => {
    if (loading) {
      setLoadingPage(true);
      return;
    }
    if (error) {
      setLoadingPage(false);
      return;
    }
    const primaryColor = apiData?.merchant?.primaryColor || null;
    const secondaryColor = apiData?.merchant?.secondaryColor || null;

    setValidTemplate(
      isPossibleTemplate(apiData?.transaction?.template as number)
    );

    const awaitColorChange = async (
      primaryColor: Nullable<string>,
      secondaryColor: Nullable<string>
    ) => {
      savePrimaryColor(primaryColor as string);
      saveSecondaryColor(secondaryColor as string);
      await awaitFunction(500, () => null);
      setLoadingPage(false);
    };
    awaitColorChange(primaryColor, secondaryColor);
  }, [apiData, loading, error, savePrimaryColor, saveSecondaryColor]);

  useEffect(() => {
    if (loading || error) return;
    if (!selectedDetailPaymentMethod) {
      setCanPay(false);
      return;
    }

    if (!isSubscriptionPaymentMethod(selectedDetailPaymentMethod?.handler)) {
      setCanPay(true);
      return;
    }

    setCanPay(selectedCard !== null);
  }, [selectedCard, selectedDetailPaymentMethod, loading, error]);

  const getCardsInfo = async (pm: PaymentMethodOutputData) => {
    setSubscriptionCardsLoading(true);
    try {
      const url = `${PORTAL_URL}/payments/${id}/subscriptions?payment_method_id=${pm.id}`;
      const headers = {
        'Content-Type': 'application/json',
      };

      const res = await axios.get(url, {
        headers,
      });

      if (res.status === 200) {
        const { data } = res;
        const parsedData = subscriptionCardsParser(data);
        setSubscriptionCards(parsedData);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setSubscriptionCardsLoading(false);
    }
  };

  const generateOneTimePassword: () => Promise<boolean> = async () => {
    if (!selectedCard) return false;
    try {
      const url = `${PORTAL_URL}/payments/pay/password`;
      const headers = {
        'Content-Type': 'application/json',
      };
      const data = {
        payment_id: id,
        payment_method_id: `${selectedDetailPaymentMethod?.name}|${encondeSubscriptionCard(selectedCard)}`,
      };

      const res = await axios.post(url, data, {
        headers,
      });

      if (res.status === 201) {
        return true;
      }
      return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  const handleSelectGeneralPaymentMethod: (paymentMethodId: string) => void = (
    paymentMethodId
  ) => {
    if (!apiData) return;
    const paymentMethod = apiData.generalPaymentMethods.filter(
      (pm: PaymentMethodOutputData) => pm.id === paymentMethodId
    )[0];

    setSelectedDetailPaymentMethod(null);
    setSubscriptionCards([]);
    setSelectedCard(null);
    if (selectedGeneralPaymentMethod?.id === paymentMethod?.id) {
      setSelectedGeneralPaymentMethod(null);
      setDetailsPaymentMethods([]);
      return;
    }

    setSelectedGeneralPaymentMethod(paymentMethod);
    setDetailsPaymentMethods(
      apiData?.paymentMethods[
        paymentMethod.handler as keyof GeneralPaymentMethods
      ] || []
    );

    if (
      (
        apiData?.paymentMethods[
          paymentMethod.handler as keyof GeneralPaymentMethods
        ] || []
      ).length == 1
    ) {
      const pm =
        apiData?.paymentMethods[
          paymentMethod.handler as keyof GeneralPaymentMethods
        ][0];
      setSelectedDetailPaymentMethod(pm);

      if (isSubscriptionPaymentMethod(pm.handler)) {
        getCardsInfo(pm);
      }
    } else if (
      apiData?.paymentMethods.other.filter(
        (pm: PaymentMethodOutputData) => pm.id === paymentMethod.id
      ).length === 1
    ) {
      setSelectedDetailPaymentMethod(paymentMethod);
    }
  };

  const handleSelectDetailPaymentMethod = (
    paymentMethod: Nullable<PaymentMethodOutputData>
  ) => {
    setSelectedDetailPaymentMethod(paymentMethod);

    if (
      isSubscriptionPaymentMethod(paymentMethod?.handler as string) &&
      paymentMethod
    ) {
      getCardsInfo(paymentMethod);
    }
  };

  const handleSelectSubscriptionCard = (
    subscriptionCard: SubscriptionCardOutputData
  ) => {
    if (selectedCard?.id === subscriptionCard.id) {
      setSelectedCard(null);
      return;
    }

    setSelectedCard(subscriptionCard);
  };

  const handleDeleteSubscriptionCard = async (
    sub: SubscriptionCardOutputData
  ) => {
    const fetchDeleteSubscription = async () => {
      try {
        const url = `${PORTAL_URL}/subscriptions/${sub.slug}`;
        const headers = {
          'Content-Type': 'application/json',
        };
        const data = {
          order: id,
          payment_method: selectedDetailPaymentMethod?.id,
        };

        await axios.delete(url, {
          headers,
          data,
        });

        if (selectedDetailPaymentMethod) {
          getCardsInfo(selectedDetailPaymentMethod);
        }
      } catch (e) {
        console.log(e);
      }
    };
    await fetchDeleteSubscription();
  };

  return (
    <>
      {loadingPage && <LoadingScreen message={null} />}
      {!loadingPage && apiData && validTemplate && (
        <PaymentBaseTemplateOne
          canPay={canPay}
          apiData={apiData}
          subscriptionCardsLoading={subscriptionCardsLoading}
          handleSelectSubscriptionCard={handleSelectSubscriptionCard}
          selectedCard={selectedCard}
          detailsPaymentMethods={detailsPaymentMethods}
          selectedGeneralPaymentMethod={selectedGeneralPaymentMethod}
          handleSelectGeneralPaymentMethod={handleSelectGeneralPaymentMethod}
          selectedDetailPaymentMethod={selectedDetailPaymentMethod}
          handleSelectDetailPaymentMethod={handleSelectDetailPaymentMethod}
          subscriptionCards={subscriptionCards}
          handleDeleteCard={handleDeleteSubscriptionCard}
          generateOneTimePassword={generateOneTimePassword}
        />
      )}
      {!loadingPage && (error || !validTemplate) && <ErrorScreen />}
    </>
  );
}

export default Payments;
