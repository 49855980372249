import { FC, useEffect, useState } from 'react';

interface ErrorAlertToastProps {
  error: string;
  message: string;
  closeToast: () => void;
}

const ErrorAlertToast: FC<ErrorAlertToastProps> = (props) => {
  const { error, message, closeToast } = props;
  const [enter, setEnter] = useState<boolean>(false);

  useEffect(() => {
    setEnter(true);
  }, []);

  return (
    <div
      className="fixed flex max-w-xl bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded right-0 lg:right-5 transition-all ease-in-out duration-500 z-10"
      style={{ transitionDelay: 0.5 + 's', bottom: enter ? '40px' : '-100px' }}
      role="alert"
    >
      <strong className="font-bold text-sm text-nowrap my-auto">
        [{error}]
      </strong>
      <span className="block sm:inline text-sm text-wrap">{message}</span>
      <span className="my-auto">
        <svg
          className="fill-current h-6 w-6 text-red-500"
          role="button"
          onClick={closeToast}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <title>Cerrar</title>
          <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
        </svg>
      </span>
    </div>
  );
};

export default ErrorAlertToast;
