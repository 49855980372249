import { FC, useEffect, useState } from 'react';

// middlewares
import {
  PaymentMethodOutputData,
  PaymentsParserOutputData,
  SubscriptionCardOutputData,
} from '../../../../middlewares/Payments/types';

// components
import Layout from '../../common/Layout';
import PaymentHeader from '../Header/PaymentHeader';
import PaymentMethodSelector from '../Selector/PaymentMethodSelector';

// utils
import { Nullable } from '../../../../utils/customTypes';
import DeleteSubscriptionCardModal from '../Options/DeleteSubscriptionCardModal';
import ConfirmPaymentCardPassword from '../Options/ConfirmPaymentCardPassword';
import isSubscriptionPaymentMethod from '../../../../utils/payments/isSubscriptionPaymentMethod';
import { useSearchParams } from 'react-router-dom';
import ErrorAlertToast from '../../../shared/ErrorAlertToast';
import useIsTemplate from '../../../../hooks/useIsTemplate';
import ColorPickerIcon from '../../../shared/ColorPickerIcon';
import ChangeColorModal from '../../common/ChangeColorModal';

interface PaymentBaseTemplateOneProps {
  canPay: boolean;
  subscriptionCards: Array<SubscriptionCardOutputData>;
  subscriptionCardsLoading: boolean;
  selectedCard: Nullable<SubscriptionCardOutputData>;
  apiData: PaymentsParserOutputData;
  detailsPaymentMethods: PaymentMethodOutputData[];
  selectedGeneralPaymentMethod: Nullable<PaymentMethodOutputData>;
  handleSelectGeneralPaymentMethod: (paymentMethodSlug: string) => void;
  selectedDetailPaymentMethod: Nullable<PaymentMethodOutputData>;
  handleSelectDetailPaymentMethod: (
    paymentMethod: Nullable<PaymentMethodOutputData>
  ) => void;
  handleSelectSubscriptionCard: (
    subscriptionCard: SubscriptionCardOutputData
  ) => void;
  handleDeleteCard: (sub: SubscriptionCardOutputData) => void;
  generateOneTimePassword: () => Promise<boolean>;
}

const PaymentBaseTemplateOne: FC<PaymentBaseTemplateOneProps> = (props) => {
  const {
    canPay,
    subscriptionCards,
    subscriptionCardsLoading,
    selectedCard,
    apiData,
    detailsPaymentMethods,
    selectedGeneralPaymentMethod,
    handleSelectGeneralPaymentMethod,
    selectedDetailPaymentMethod,
    handleSelectDetailPaymentMethod,
    handleSelectSubscriptionCard,
    handleDeleteCard,
    generateOneTimePassword,
  } = props;
  const [searchParams] = useSearchParams();
  const { isTemplate } = useIsTemplate();
  const errorQuery = searchParams.get('error');
  const [showErrorToast, setShowErrorToast] = useState<boolean>(false);
  const [showColorModal, setShowColorModal] = useState<boolean>(false);
  const [subscriptionToDelete, setSubscriptionToDelete] =
    useState<Nullable<SubscriptionCardOutputData>>(null);
  const [showDeleteSubscriptionModal, setDeleteSubscriptionModal] =
    useState<boolean>(true);
  const [showConfirmCardPaymentModal, setShowConfirmCardPaymentModal] =
    useState<boolean>(false);
  const [loadingPayButton, setLoadingPayButton] = useState<boolean>(false);

  useEffect(() => {
    if (errorQuery) {
      setShowErrorToast(true);
    }
  }, [errorQuery]);

  const handleTryDeleteSubscription = (sub: SubscriptionCardOutputData) => {
    setSubscriptionToDelete(sub);
    setDeleteSubscriptionModal(true);
  };

  const handleCloseModal = () => {
    setSubscriptionToDelete(null);
    setDeleteSubscriptionModal(false);
  };
  const handleCloseConfirmPaymentCardModal = () => {
    setShowConfirmCardPaymentModal(false);
  };

  const handleDeleteSubscriptionCard = async () => {
    if (subscriptionToDelete) {
      await handleDeleteCard(subscriptionToDelete);
      setSubscriptionToDelete(null);
      setDeleteSubscriptionModal(false);
    }
  };

  const handlePayClick = async () => {
    setLoadingPayButton(true);
    if (
      isSubscriptionPaymentMethod(
        selectedDetailPaymentMethod?.handler as string
      )
    ) {
      if (!selectedCard) return;
      const success = await generateOneTimePassword();
      if (success) {
        setShowConfirmCardPaymentModal(true);
      }
    }
    setLoadingPayButton(false);
  };

  return (
    <>
      <Layout>
        <div className="h-auto w-full flex flex-col">
          <PaymentHeader
            merchantLogo={apiData?.merchant?.logo}
            merchantName={apiData?.merchant?.name}
            transactionAmount={apiData?.transaction?.amount}
          />
          <PaymentMethodSelector
            canPay={canPay}
            subscriptionCards={subscriptionCards}
            subscriptionCardsLoading={subscriptionCardsLoading}
            selectedCard={selectedCard}
            metricsToken={apiData?.metricsToken}
            paymentMethods={apiData?.generalPaymentMethods}
            detailsPaymentMethods={detailsPaymentMethods}
            selectedGeneralPaymentMethod={selectedGeneralPaymentMethod}
            handleSelectGeneralPaymentMethod={handleSelectGeneralPaymentMethod}
            selectedDetailPaymentMethod={selectedDetailPaymentMethod}
            handleSelectDetailPaymentMethod={handleSelectDetailPaymentMethod}
            handleSelectSubscriptionCard={handleSelectSubscriptionCard}
            handleTryDeleteSubscription={handleTryDeleteSubscription}
            handlePayClick={handlePayClick}
            loadingPayButton={loadingPayButton}
          />
        </div>
      </Layout>
      {showDeleteSubscriptionModal && subscriptionToDelete && (
        <DeleteSubscriptionCardModal
          subscriptionCard={subscriptionToDelete}
          closeModal={handleCloseModal}
          handleDelete={handleDeleteSubscriptionCard}
        />
      )}
      {showConfirmCardPaymentModal &&
        selectedCard &&
        selectedDetailPaymentMethod && (
          <ConfirmPaymentCardPassword
            subscriptionCard={selectedCard}
            paymentMethod={selectedDetailPaymentMethod}
            metricsToken={apiData?.metricsToken}
            closeModal={handleCloseConfirmPaymentCardModal}
          />
        )}
      {showErrorToast && (
        <ErrorAlertToast
          error={`${errorQuery}`}
          message="No se pudo completar la transacción, intentalo de nuevo con otro medio de pago."
          closeToast={() => setShowErrorToast(false)}
        />
      )}
      {isTemplate && <ColorPickerIcon openModal={setShowColorModal} />}
      {isTemplate && showColorModal && (
        <ChangeColorModal closeModal={setShowColorModal} />
      )}
    </>
  );
};

export default PaymentBaseTemplateOne;
