import { FC } from 'react';

// middlewares
import {
  OpenParserOutputData,
  SubscriptionInputType,
} from '../../../../middlewares/Open/openTypes';

// components
import Layout from '../../common/Layout';
import SubscriptionHeader from '../Header/SubscriptionHeader';
import BaseSubscriptionFields from '../Subscriptions/BaseSubscriptionFields';

interface SubscriptionBaseTemplateOneProps {
  apiData: OpenParserOutputData;
  subscriptionInputs: SubscriptionInputType;
  handleChangeInput: (
    name: keyof SubscriptionInputType,
    value: string | number | boolean,
    type: string
  ) => void;
  handleSubscriptionSubmit: () => void;
}

const SubscriptionBaseTemplateOne: FC<SubscriptionBaseTemplateOneProps> = (
  props
) => {
  const {
    apiData,
    subscriptionInputs,
    handleChangeInput,
    handleSubscriptionSubmit,
  } = props;

  return (
    <Layout shape="bigger">
      <div className="h-auto w-full flex flex-col">
        <SubscriptionHeader
          merchantLogo={apiData.merchant.logo}
          merchantName={apiData.merchant.name}
        />
        <BaseSubscriptionFields
          apiData={apiData}
          subscriptionInputs={subscriptionInputs}
          handleChangeInput={handleChangeInput}
          handleSubscriptionSubmit={handleSubscriptionSubmit}
        />
      </div>
    </Layout>
  );
};

export default SubscriptionBaseTemplateOne;
