import { FC, memo } from 'react';

// hooks
import useColors from '../../../../hooks/useColors';

// components
import LoadInputsSelector from './LoadInputsSelector';

// utils
import { Nullable } from '../../../../utils/customTypes';
import { OpenInputType } from '../../../../middlewares/Open/openTypes';

interface OpenHeaderProps {
  merchantLogo: Nullable<string>;
  merchantName: string;
  loadedInputs: Nullable<OpenInputType>;
  storable: boolean | null;
  loadOpenStoredInputs: () => void;
  loadedInputsMetadata: {
    name: boolean;
    email: boolean;
    amount: boolean;
    extra: boolean;
  };
}

const OpenHeader: FC<OpenHeaderProps> = (props) => {
  const {
    merchantLogo,
    merchantName,
    storable,
    loadedInputs,
    loadedInputsMetadata,
    loadOpenStoredInputs,
  } = props;
  const { secondaryColor } = useColors();

  return (
    <div className="h-2/6 lg:h-1/6 w-full flex flex-col lg:flex-row justify-between py-3 mt-2 lg:py-7">
      <div className="h-full lg:mt-0 w-full lg:w-3/4 flex flex-col lg:flex-row">
        <div className="mx-auto mt-auto w-full lg:w-auto lg:mx-0">
          <h1 className="text-3xl font-roboto font-medium mr-4">
            Estás pagando en:
          </h1>
        </div>
        <div
          className={`mx-auto mt-5 w-full lg:mt-auto lg:w-auto lg:mx-0 h-full flex ${merchantLogo ? 'items-end' : 'items-end'}`}
        >
          {merchantLogo ? (
            <img
              src={merchantLogo}
              className="max-h-16 lg:max-h-10 max-w-80 mx-auto lg:mx-0 object-contain"
              alt="logo"
            />
          ) : (
            <h1
              className="text-4xl font-roboto font-bold mx-auto lg:mx-0 align-baseline"
              style={{ color: secondaryColor }}
            >
              {merchantName || ''}
            </h1>
          )}
        </div>
      </div>
      <div className="w-full lg:w-1/4 lg:mt-auto items-end">
        {storable && (
          <LoadInputsSelector
            loadedInputs={loadedInputs}
            loadedInputsMetadata={loadedInputsMetadata}
            loadOpenStoredInputs={loadOpenStoredInputs}
          />
        )}
      </div>
    </div>
  );
};

export default memo(OpenHeader);
