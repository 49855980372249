import { FC, MouseEvent } from 'react';

// middlewares
import {
  LinkConfigurationsOutputData,
  OpenInputType,
} from '../../../../middlewares/Open/openTypes';

// components
import CustomInput from '../../common/CustomInput';

// icon
import { ReactComponent as AlertIcon } from '../../../../assets/feather/alert-octagon.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/feather/trash-2.svg';

interface TextFieldProps {
  inputs: OpenInputType;
  linkConfiguration: LinkConfigurationsOutputData;
  dismissed: boolean;
  onDismissField: (name: string, dismiss: boolean) => void;
  error: string | null;
}

const TextField: FC<TextFieldProps> = (props) => {
  const { inputs, linkConfiguration, dismissed, onDismissField, error } = props;

  const handleDismissButton = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onDismissField(linkConfiguration.name, true);
  };

  return (
    <div
      className={`mx-auto w-11/12 text-left ${linkConfiguration.hidden || dismissed ? 'hidden' : ''}`}
    >
      <div className="flex justify-between">
        <label htmlFor="name" className="text-sm font-semibold w-full mr-auto">
          {linkConfiguration.label}:
        </label>
        {linkConfiguration.dismissable && (
          <button className="max-h-4 my-auto" onClick={handleDismissButton}>
            <TrashIcon className="max-h-4 my-auto text-stone-500" />
          </button>
        )}
      </div>
      <CustomInput
        id={linkConfiguration.name}
        name={linkConfiguration.name}
        placeholder={linkConfiguration.placeholder}
        value={inputs[linkConfiguration.name]}
        disabled={linkConfiguration.value !== ''}
      />
      {error && (
        <div className="flex text-red-500">
          <AlertIcon className="h-4 my-auto" />
          <p className="text-sm">{error}</p>
        </div>
      )}
    </div>
  );
};

export default TextField;
