import { FC, MouseEvent, useState } from 'react';
import useColors from '../../hooks/useColors';

interface ButtonProps {
  text: string | React.ReactNode;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  value: string | number | readonly string[];
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
}

const Button: FC<ButtonProps> = (props) => {
  const { text, onClick, value, disabled, type } = props;
  const { primaryColor, secondaryColor } = useColors();
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <button
      type={type ? type : 'button'}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      value={value}
      disabled={disabled}
      className="
        border-2 px-8 py-1 rounded-full font-semibold font-roboto transition-all duration-200
        disabled:shadow-none shadow-sm text-white disabled:text-[#808080]
      "
      style={{
        backgroundColor: disabled
          ? '#F5F5F5'
          : isHover
            ? secondaryColor
            : primaryColor,
        fontSize: '20px',
        borderColor: disabled
          ? '#808080'
          : isHover
            ? secondaryColor
            : primaryColor,
      }}
    >
      {text}
    </button>
  );
};

export default Button;
