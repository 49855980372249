import { FC } from 'react';
import {
  PaymentOutputData,
  TransactionRequestOutputData,
} from '../../../../middlewares/Return/returnTypes';
import useColors from '../../../../hooks/useColors';

interface PaymentStatusProps {
  payment: PaymentOutputData;
  transactionRequest: TransactionRequestOutputData;
}

const PaymentStatus: FC<PaymentStatusProps> = (props) => {
  const { payment, transactionRequest } = props;
  const { secondaryColor } = useColors();
  return (
    <div className="w-full my-auto py-4 px-8 md:w-1/2">
      <h1 className="text-lg font-bold mb-4" style={{ color: secondaryColor }}>
        Estado del pago:
      </h1>
      <div className="text-sm">
        {payment.valid && (
          <p className="font-medium">{payment.statusMessage}</p>
        )}
        {!payment.valid && (
          <p className="font-semibold">No se pudo realizar el pago</p>
        )}
        {!payment.valid && transactionRequest.errors.length > 0 && (
          <div className="overflow-y-auto w-full mt-4 max-h-24 text-left">
            <p className="mr-auto mb-1">Errores:</p>
            <ul className="list-disc">
              {transactionRequest.errors.map((errMessage, i) => (
                <li key={`${i}-${errMessage}`} className="mb-1 text-red-500">
                  - {errMessage}
                </li>
              ))}
            </ul>
          </div>
        )}
        {!payment.valid &&
          transactionRequest.errors.length === 0 &&
          transactionRequest.caducated && <p>La transacción ha caducado</p>}
        {!payment.valid &&
          transactionRequest.errors.length === 0 &&
          !transactionRequest.caducated && <p>Vuelve a intentarlo más tarde</p>}
      </div>
    </div>
  );
};

export default PaymentStatus;
