const formatRut = (rut: string) => {
  if (rut.length <= 1) return rut;

  const newRut = rut.slice(0, -1);
  const digv = rut.slice(-1);
  return `${newRut.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}-${digv.toUpperCase()}`;
};

const digitVerifier = (T: number) => {
  let M = 0;
  let S = 1;
  for (; T; T = Math.floor(T / 10)) {
    S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
  }
  return S ? `${S - 1}` : 'k';
};

const isRut: (input: string) => [string, boolean] = (rutToVerify: string) => {
  let hasError = false;
  const separatedRut = rutToVerify.replace(/\.|-/g, '');
  const rut = separatedRut.slice(0, -1) || '';
  const digVer = separatedRut.slice(-1) || '';

  if (Number.isNaN(Number(rut))) hasError = true;

  if (digitVerifier(Number(rut)) !== digVer.toLowerCase()) hasError = true;

  return [formatRut(rut + digVer), hasError];
};

export default isRut;
