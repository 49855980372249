import { ReturnParserInputData } from '../returnTypes';

const returnPaymentData: ReturnParserInputData = {
  status: 200,
  data: {
    merchant: {
      id: 1,
      name: 'Prueba',
      host: 'https://www.pagohub.cl/',
      email: '-',
      preferred_template: 1,
      logo: null,
      colors: '{}',
      google_analytics_token: null,
    },
    transaction_request: {
      slug: '11111-11111-11111',
      amount: 1000000,
      identifier: 'ident_001',
      name: 'prueba',
      email: '',
      caducated: false,
      errors: [['Aparentemente hay un problema de Conectividad']],
    },
    subscription: {
      id: null,
      message: null,
      missing: null,
    },
    payment: {
      valid: true,
      status: 'Transacción cancelada, sin ir a medio de pago',
    },
    is_subscription: false,
  },
};

export default returnPaymentData;
