import { PaymentsParserInputData } from '../types';

const templatePaymentData: PaymentsParserInputData = {
  status: 200,
  data: {
    transaction: {
      external_transaction_id: 'abcde_fghijk_lmn-opq',
      slug: 'ABCDEFG',
      amount: 1000000,
      template: 1,
      return_url: '',
    },
    merchant: {
      id: 1,
      name: 'Prueba',
      logo: null,
      colors: '{}',
    },
    payment_methods: [
      {
        id: '1',
        name: 'fintoc-subscription',
        external_id: 'Fintoc-1',
        meta: {
          logo: 'fintoc_logo.svg',
          title: 'Fintoc Suscripción',
          description: 'Paga seguro suscribiendote con Fintoc',
        },
        config: {
          price_range: {
            maximum: 'Unlimited',
            min: 0,
            max: 2147483647,
          },
          position: 10,
        },
        handler: 'Fintoc::Subscriptions::V1::Plugin',
      },
      {
        id: '2',
        name: 'fintoc-payment',
        external_id: 'Fintoc-2',
        meta: {
          logo: 'fintoc_logo.svg',
          title: 'Fintoc Pago',
          description: 'Transferencia electrónica con Fintoc',
        },
        config: {
          price_range: {
            maximum: 'Unlimited',
            min: 0,
            max: 2147483647,
          },
          position: 10,
        },
        handler: 'Fintoc::V1::Plugin',
      },
      {
        id: '3',
        name: 'webpay',
        external_id: 'WebpayTest-1',
        meta: {
          logo: 'https://alpayments.s3.amazonaws.com/payment-gateway/logo-webpay.png',
          title: 'Webpay Plus',
          description: 'Paga seguro con Transbank',
        },
        config: {
          price_range: {
            maximum: 'Unlimited',
            min: 0,
            max: 2147483647,
          },
          position: 10,
        },
        handler: 'TbkProxy::Webpay',
      },
      {
        id: '4',
        name: 'webpay-oneclick',
        external_id: 'OneClick-1',
        meta: {
          logo: 'https://alpayments.s3.amazonaws.com/payment-gateway/logo-webpay_oneclick.jpg',
          title: 'Transbank WebPay OneClick',
          description: 'Suscríbete y simplifica tu proceso de pago',
        },
        config: {
          price_range: {
            maximum: 1000000,
            min: 0,
            max: 1000000,
          },
          position: 10,
        },
        handler: 'TbkProxy::OneClickMall',
      },
    ],
    payment_cancel_path: '/payments/ABCDEFG/cancel',
    metrics_token: '',
  },
  warnings: null,
};

export default templatePaymentData;
