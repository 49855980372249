import { FC, MouseEvent } from 'react';

// middlewares
import {
  LinkConfigurationsOutputData,
  OpenInputType,
} from '../../../../middlewares/Open/openTypes';

// components
import CustomTextArea from '../../common/CustomTextArea';

// utils
import genRandomHex from '../../../../utils/genRandomHex';

// icon
import { ReactComponent as TrashIcon } from '../../../../assets/feather/trash-2.svg';

interface DescriptionExtensionFieldProps {
  inputs: OpenInputType;
  linkConfiguration: LinkConfigurationsOutputData;
  dismissed: boolean;
  onDismissField: (name: string, dismiss: boolean) => void;
}

const DescriptionExtensionField: FC<DescriptionExtensionFieldProps> = (
  props
) => {
  const { inputs, linkConfiguration, dismissed, onDismissField } = props;
  const id = genRandomHex(4);

  const handleDismissButton = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onDismissField(linkConfiguration.name, true);
  };

  return (
    <div
      id={id}
      className={`mx-auto w-11/12 text-left ${linkConfiguration.hidden || dismissed ? 'hidden' : ''}`}
    >
      <div className="flex justify-between">
        <label htmlFor="name" className="text-sm font-semibold w-full mr-auto">
          {linkConfiguration.label}:
        </label>
        {linkConfiguration.dismissable && (
          <button className="max-h-4 my-auto" onClick={handleDismissButton}>
            <TrashIcon className="max-h-4 my-auto text-stone-500" />
          </button>
        )}
      </div>
      <CustomTextArea
        id={linkConfiguration.name}
        name={linkConfiguration.name}
        placeholder={linkConfiguration.placeholder}
        value={inputs[linkConfiguration.name]}
        disabled={linkConfiguration.value !== ''}
      />
    </div>
  );
};

export default DescriptionExtensionField;
