import { ChangeEvent, FC } from 'react';

// middlewares
import {
  PaymentRequestOutputData,
  SubscriptionInputType,
} from '../../../../middlewares/Open/openTypes';

// hooks
import useColors from '../../../../hooks/useColors';

// components
import CustomInput from '../../common/CustomInput';

// utils
import isEmail from '../../../../utils/isEmail';

// icons
import { ReactComponent as AlertIcon } from '../../../../assets/feather/alert-octagon.svg';

interface PersonalInformationProps {
  paymentRequestData: PaymentRequestOutputData;
  subscriptionInputs: SubscriptionInputType;
  handleChangeInput: (
    name: keyof SubscriptionInputType,
    value: string | number | boolean,
    type: string
  ) => void;
}

const PersonalInformation: FC<PersonalInformationProps> = (props) => {
  const { paymentRequestData, subscriptionInputs, handleChangeInput } = props;
  const { secondaryColor } = useColors();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    handleChangeInput(name as keyof SubscriptionInputType, value, 'text');
  };

  return (
    <div className="w-full lg:w-1/2 text-justify px-10 lg:pr-15 lg:border-r-2 border-r-amber-400">
      <h1 className="text-2xl font-bold mb-5">Personal</h1>
      <p>Verifica que tu información sea válida</p>
      <div className="mx-auto lg:mx-0 w-[95%] text-left my-4">
        <label
          htmlFor="name"
          className="text-sm font-semibold w-full mr-auto"
          style={{ color: secondaryColor }}
        >
          Nombre cliente:
        </label>
        <CustomInput
          id="name"
          name="name"
          placeholder="Ingrese su nombre"
          value={subscriptionInputs.name}
          handleChange={handleChange}
          disabled={paymentRequestData.name !== ''}
        />
      </div>
      <div className="mx-auto lg:mx-0 w-[95%] text-left my-4">
        <label
          htmlFor="email"
          className="text-sm font-semibold w-full mr-auto"
          style={{ color: secondaryColor }}
        >
          Email:
        </label>
        <CustomInput
          id="email"
          name="email"
          placeholder="Ingrese su email"
          handleChange={handleChange}
          value={subscriptionInputs.email}
          disabled={paymentRequestData.email !== ''}
        />
        {subscriptionInputs.email && !isEmail(subscriptionInputs.email) && (
          <div className="flex text-red-500">
            <AlertIcon className="h-4 my-auto" />
            <p className="text-sm">El mail indicado no es válido</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PersonalInformation;
