import { Nullable } from './customTypes';

const defaultPrimary = '#298EC5';
const defaultSecondary = '#1B1464';

const isHexColor: (color: Nullable<string>) => boolean = (
  color: Nullable<string>
) => {
  const regex = /^#[0-9A-F]{6}$/i;
  return regex.test(color || '');
};

const colorsParser: (color: Nullable<string>, type: string) => string = (
  color: Nullable<string>,
  type: string
) => {
  if (!isHexColor(color)) {
    return type === 'primary' ? defaultPrimary : defaultSecondary;
  }
  return color || (type === 'primary' ? defaultPrimary : defaultSecondary);
};

export const fontColorParser: (color: string) => string = (color: string) => {
  if (!color) return 'text-primary';
  if (color === 'primary' || color === 'secondary') return `text-${color}`;
  return `text-[${color}]`;
};

export const backgroundColorParser: (color: string) => string = (
  color: string
) => {
  if (!color) return 'bg-primary';
  if (color === 'primary' || color === 'secondary') return `bg-${color}`;
  return `bg-[${color}]`;
};

export const borderColorParser: (color: string) => string = (color: string) => {
  if (!color) return 'border-primary';
  if (color === 'primary' || color === 'secondary') return `border-${color}`;
  return `border-[${color}]`;
};

export default colorsParser;
