import { FC, useState } from 'react';
import useColors from '../../../../hooks/useColors';
import { SubscriptionCardOutputData } from '../../../../middlewares/Payments/types';

interface SubscriptionCardOneClickProps {
  subscriptionCard: SubscriptionCardOutputData;
  isSelected: boolean;
  handleSelectSubscriptionCard: (
    subscriptionCard: SubscriptionCardOutputData
  ) => void;
  handleTryDeleteSubscription: (
    subscriptionCard: SubscriptionCardOutputData
  ) => void;
}

const SubscriptionCardOneClick: FC<SubscriptionCardOneClickProps> = (props) => {
  const {
    subscriptionCard,
    isSelected,
    handleSelectSubscriptionCard,
    handleTryDeleteSubscription,
  } = props;
  const [isHover, setIsHover] = useState<boolean>(false);
  const { primaryColor } = useColors();

  const handleSubscriptionCardClick = () => {
    handleSelectSubscriptionCard(subscriptionCard);
  };
  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <div>
      <input
        id={`checkbox-card-${subscriptionCard.id}`}
        className="hidden peer"
        type="checkbox"
        checked={isSelected}
        value=""
        style={{ accentColor: primaryColor }}
        onChange={handleSubscriptionCardClick}
      />
      <label
        htmlFor={`checkbox-card-${subscriptionCard.id}`}
        className="flex justify-between p-2 m-2 border-2 rounded-lg cursor-pointer"
        style={{
          borderColor: isHover || isSelected ? primaryColor : '#cac9c9',
          color: isSelected ? primaryColor : 'black',
          backgroundColor: isHover ? '#F8F8F8' : 'white',
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="flex w-full justify-evenly">
          <div className="flex">
            <img
              src={subscriptionCard.cardLogo}
              className="my-auto max-h-12 max-w-32"
            />
          </div>
          <div className="text-sm">
            <p>xxxx-{subscriptionCard.cardDigits}</p>
            <p>{subscriptionCard.name}</p>
            <p>{subscriptionCard.email}</p>
          </div>
        </div>
        <div className="col-12 my-auto p-3 text-right delete-subscription">
          <button
            className="hover:text-red-600"
            aria-label="Eliminar tarjeta"
            onClick={() => handleTryDeleteSubscription(subscriptionCard)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </label>
    </div>
  );
};

export default SubscriptionCardOneClick;
