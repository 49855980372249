import React, { Context, FC, useState } from 'react';

interface UseProvideIsTemplateProps {
  children: React.ReactNode;
}

interface IsTemplateContextValues {
  isTemplate: boolean;
  saveIsTemplate: (value: boolean) => void;
}

export const IsTemplateContext: Context<IsTemplateContextValues> =
  React.createContext({
    isTemplate: false !== false,
    saveIsTemplate: (value: boolean) => console.log(value),
  });

const useProvideIsTemplate = () => {
  const [isTemplate, setIsTemplate] = useState(false);

  const saveIsTemplate: (value: boolean) => void = (value: boolean) => {
    setIsTemplate(value);
  };

  return {
    isTemplate,
    saveIsTemplate,
  };
};

const IsTemplateProvider: FC<UseProvideIsTemplateProps> = (props) => {
  const { children } = { ...props };
  const { isTemplate, saveIsTemplate } = useProvideIsTemplate();
  return (
    <IsTemplateContext.Provider
      value={{
        isTemplate,
        saveIsTemplate,
      }}
    >
      {children}
    </IsTemplateContext.Provider>
  );
};

export default IsTemplateProvider;
