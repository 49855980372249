import { SubscriptionCardsInputData } from '../types';

const templateSubscriptionCardsData: SubscriptionCardsInputData = {
  status: 200,
  data: [
    {
      id: 1,
      slug: 'card_1',
      name: 'Tarjeta de prueba',
      email: 'example@mail.cl',
      card_digits: '1234',
      card_type: 'RedCompra',
      created_at: 1,
    },
    {
      id: 2,
      slug: 'card_2',
      name: 'Tarjeta de prueba 2',
      email: 'example@mail.cl',
      card_digits: '5678',
      card_type: 'Visa',
      created_at: 1,
    },
    {
      id: 3,
      slug: 'card_3',
      name: 'Tarjeta de prueba 3',
      email: 'example@mail.cl',
      card_digits: '9012',
      card_type: 'AmericanExpress',
      created_at: 1,
    },
  ],
};

export default templateSubscriptionCardsData;
