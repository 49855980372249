import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

// hooks
import useColors from '../../hooks/useColors';
import useIsTemplate from '../../hooks/useIsTemplate';
import usePortalFetch from '../../hooks/usePortalFetch';

// middlewares
import paymentsParser, {
  templateParser,
} from '../../middlewares/Payments/paymentsParser';
import {
  GeneralPaymentMethods,
  PaymentMethodOutputData,
  PaymentsParserOutputData,
  SubscriptionCardOutputData,
} from '../../middlewares/Payments/types';
import templatePaymentData from '../../middlewares/Payments/template/paymentData';
import templateSubscriptionCardsData from '../../middlewares/Payments/template/subscriptionCardsData';
import subscriptionCardsParser from '../../middlewares/Payments/subscriptionCardsParser';

// components
import PaymentBaseTemplateOne from '../../components/t1/payments/Base/PaymentsBaseTemplateOne';
import LoadingScreen from '../../components/common/LoadingScreen';
import TokenErrorScreen from '../../components/common/TokenErrorScreen';

// utils
import awaitFunction from '../../utils/awaitFunction';
import isSubscriptionPaymentMethod from '../../utils/payments/isSubscriptionPaymentMethod';
import isPossibleTemplate from '../../utils/isPossibleTemplates';
import { Nullable } from '../../utils/customTypes';

const PaymentsTemplate = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const price = searchParams.get('price');
  const { saveIsTemplate } = useIsTemplate();
  const { savePrimaryColor, saveSecondaryColor } = useColors();
  const [loadingPage, setLoadingPage] = useState<boolean>(true);
  const [canPay, setCanPay] = useState<boolean>(false);
  const [validTemplate, setValidTemplate] = useState<boolean>(false);
  const [selectedGeneralPaymentMethod, setSelectedGeneralPaymentMethod] =
    useState<Nullable<PaymentMethodOutputData>>(null);
  const [selectedDetailPaymentMethod, setSelectedDetailPaymentMethod] =
    useState<Nullable<PaymentMethodOutputData>>(null);
  const [detailsPaymentMethods, setDetailsPaymentMethods] = useState<
    PaymentMethodOutputData[]
  >([]);
  const subscriptionCardsLoading = false;
  const [subscriptionCards, setSubscriptionCards] = useState<
    Array<SubscriptionCardOutputData>
  >([]);
  const [selectedCard, setSelectedCard] =
    useState<Nullable<SubscriptionCardOutputData>>(null);
  const {
    apiData: validationData,
    loading,
    error: error,
  } = usePortalFetch(
    `payments/template?token=${token}&price=${price}&type=payment`,
    templateParser
  );
  const [apiData, setApiData] =
    useState<Nullable<PaymentsParserOutputData>>(null);

  useEffect(() => {
    saveIsTemplate(true);
  }, [saveIsTemplate]);

  useEffect(() => {
    if (!validationData) return;

    const templateData = paymentsParser(templatePaymentData);
    setApiData({
      ...templateData,
      merchant: validationData.merchant,
      paymentMethods: validationData.paymentMethods,
      generalPaymentMethods: validationData.generalPaymentMethods,
      transaction: {
        ...templateData.transaction,
        amount: parseInt(price as string) || templateData.transaction.amount,
      },
    });
  }, [validationData, price]);

  useEffect(() => {
    if (loading) {
      setLoadingPage(true);
      return;
    }
    if (error) {
      setLoadingPage(false);
      return;
    }
    const primaryColor = apiData?.merchant?.primaryColor || null;
    const secondaryColor = apiData?.merchant?.secondaryColor || null;

    setValidTemplate(
      isPossibleTemplate(apiData?.transaction?.template as number)
    );

    const awaitColorChange = async (
      primaryColor: Nullable<string>,
      secondaryColor: Nullable<string>
    ) => {
      savePrimaryColor(primaryColor as string);
      saveSecondaryColor(secondaryColor as string);
      await awaitFunction(500, () => null);
      setLoadingPage(false);
    };
    awaitColorChange(primaryColor, secondaryColor);
  }, [apiData, loading, error, savePrimaryColor, saveSecondaryColor]);

  useEffect(() => {
    if (loading) return;
    if (!selectedDetailPaymentMethod) {
      setCanPay(false);
      return;
    }

    if (!isSubscriptionPaymentMethod(selectedDetailPaymentMethod?.handler)) {
      setCanPay(true);
      return;
    }

    setCanPay(selectedCard !== null);
  }, [selectedCard, selectedDetailPaymentMethod, loading]);

  const generateOneTimePassword: () => Promise<boolean> = async () => {
    if (!selectedCard) return false;
    return true;
  };

  const handleSelectGeneralPaymentMethod: (paymentMethodId: string) => void = (
    paymentMethodId
  ) => {
    if (!apiData) return;
    const paymentMethod = apiData.generalPaymentMethods.filter(
      (pm: PaymentMethodOutputData) => pm.id === paymentMethodId
    )[0];

    setSelectedDetailPaymentMethod(null);
    setSubscriptionCards([]);
    setSelectedCard(null);
    if (selectedGeneralPaymentMethod?.id === paymentMethod?.id) {
      setSelectedGeneralPaymentMethod(null);
      setDetailsPaymentMethods([]);
      return;
    }

    setSelectedGeneralPaymentMethod(paymentMethod);
    setDetailsPaymentMethods(
      apiData?.paymentMethods[
        paymentMethod.handler as keyof GeneralPaymentMethods
      ] || []
    );

    if (
      (
        apiData?.paymentMethods[
          paymentMethod.handler as keyof GeneralPaymentMethods
        ] || []
      ).length == 1
    ) {
      const pm =
        apiData?.paymentMethods[
          paymentMethod.handler as keyof GeneralPaymentMethods
        ][0];
      setSelectedDetailPaymentMethod(pm);

      if (isSubscriptionPaymentMethod(pm.handler)) {
        setSubscriptionCards(
          subscriptionCardsParser(templateSubscriptionCardsData)
        );
      }
    } else if (
      apiData?.paymentMethods.other.filter(
        (pm: PaymentMethodOutputData) => pm.id === paymentMethod.id
      ).length === 1
    ) {
      setSelectedDetailPaymentMethod(paymentMethod);
    }
  };

  const handleSelectDetailPaymentMethod = (
    paymentMethod: Nullable<PaymentMethodOutputData>
  ) => {
    setSelectedDetailPaymentMethod(paymentMethod);
    setSubscriptionCards(
      subscriptionCardsParser(templateSubscriptionCardsData)
    );
  };

  const handleSelectSubscriptionCard = (
    subscriptionCard: SubscriptionCardOutputData
  ) => {
    setSelectedCard(subscriptionCard);
  };

  return (
    <>
      {loadingPage && <LoadingScreen message={null} />}
      {!loadingPage && apiData && validTemplate && (
        <PaymentBaseTemplateOne
          canPay={canPay}
          subscriptionCards={subscriptionCards}
          subscriptionCardsLoading={subscriptionCardsLoading}
          selectedCard={selectedCard}
          apiData={apiData}
          detailsPaymentMethods={detailsPaymentMethods}
          selectedGeneralPaymentMethod={selectedGeneralPaymentMethod}
          handleSelectGeneralPaymentMethod={handleSelectGeneralPaymentMethod}
          selectedDetailPaymentMethod={selectedDetailPaymentMethod}
          handleSelectDetailPaymentMethod={handleSelectDetailPaymentMethod}
          handleSelectSubscriptionCard={handleSelectSubscriptionCard}
          handleDeleteCard={() => null}
          generateOneTimePassword={generateOneTimePassword}
        />
      )}
      {!loadingPage && (error || !validTemplate) && (
        <TokenErrorScreen missingPrice={price === null} />
      )}
    </>
  );
};

export default PaymentsTemplate;
