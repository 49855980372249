import logoSearcher from './logoSearcher';
import {
  SubscriptionCardInputData,
  SubscriptionCardOutputData,
  SubscriptionCardsInputData,
} from './types';

const parseSubscription: (
  sub: SubscriptionCardInputData
) => SubscriptionCardOutputData = (sub) => {
  const {
    id,
    slug,
    name,
    email,
    card_digits: cardDigits,
    card_type: cardType,
    created_at: createdAt,
  } = sub;

  return {
    id,
    slug,
    name,
    email,
    cardDigits,
    cardType,
    createdAt,
    cardLogo: logoSearcher(cardType, true),
  };
};

const subscriptionCardsParser: (
  data: SubscriptionCardsInputData
) => Array<SubscriptionCardOutputData> = (data) => {
  const { data: apiData } = data;
  const parsedData = apiData.map((sub) => parseSubscription(sub));

  return parsedData;
};

export default subscriptionCardsParser;
