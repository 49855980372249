import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// middleware
import openParser from '../middlewares/Open/openParser';
import {
  OpenInputType,
  SubscriptionInputType,
} from '../middlewares/Open/openTypes';

// hooks
import useColors from '../hooks/useColors';
import useLinkerFetch from '../hooks/useLinkerFetch';
import useLocalStorage from '../hooks/useLocalStorage';

// components
import LoadingScreen from '../components/common/LoadingScreen';
import ErrorScreen from '../components/common/ErrorScreen';
import OpenBaseTemplateOne from '../components/t1/open/Base/OpenBaseTemplateOne';

// utils
import awaitFunction from '../utils/awaitFunction';
import { Nullable } from '../utils/customTypes';
import isPossibleTemplate from '../utils/isPossibleTemplates';
import { LINKER_URL } from '../utils/urls';
import sendMetrics from '../utils/metrics/sendMetrics';

const Open: FC = () => {
  const { id } = useParams();
  const [recheckValidations, setRecheckValidations] = useState<boolean>(false);
  const { savePrimaryColor, saveSecondaryColor } = useColors();
  const { getItem, setItem } = useLocalStorage();
  const [validTemplate, setValidTemplate] = useState<boolean>(false);
  const [loadingPage, setLoadingPage] = useState<boolean>(true);
  const [loadedInputs, setLoadedInputs] =
    useState<Nullable<OpenInputType>>(null);
  const [openInputs, setOpenInputs] = useState<OpenInputType>({
    name: '',
    email: '',
    amount: '',
  });
  const [subscriptionInputs, setSubscriptionInputs] =
    useState<SubscriptionInputType>({
      name: '',
      email: '',
      amount: '',
      termsAndConditions: false,
    });
  const [dismissedFields, setDismissedFields] = useState<{
    [key: string]: boolean;
  }>({});
  const { apiData, loading, error } = useLinkerFetch(
    `transactions/${id}`,
    openParser
  );

  useEffect(() => {
    if (loading || !apiData) return;
    if (apiData.isSubscription) return;

    const getStoredInputs = () => {
      if (!apiData?.storable) return;

      const values = getItem(`pagohub-${apiData.merchant.id}`);
      if (values) setLoadedInputs(JSON.parse(values));
    };

    getStoredInputs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, apiData]);

  useEffect(() => {
    if (loading || !apiData) return;

    if (!apiData.isSubscription) {
      setOpenInputs((values) => ({
        ...values,
        name: apiData?.paymentRequest?.name
          ? apiData?.paymentRequest?.name
          : '',
        amount: apiData?.paymentRequest?.amount
          ? apiData?.paymentRequest?.amount
          : '',
        ...(apiData?.paymentRequest?.linkConfigurations || [])
          .map((linkConfiguration) => ({
            [linkConfiguration.name]: linkConfiguration.value,
          }))
          .reduce((prev, curr) => ({ ...prev, ...curr }), {}),
      }));
    } else {
      setSubscriptionInputs((values) => ({
        ...values,
        name: apiData?.paymentRequest?.name
          ? apiData?.paymentRequest?.name
          : '',
        email: apiData?.paymentRequest?.email
          ? apiData?.paymentRequest?.email
          : '',
        amount: apiData?.paymentRequest?.amount
          ? apiData?.paymentRequest?.amount
          : '',
      }));
    }
  }, [apiData, loading]);

  useEffect(() => {
    if (loading) {
      setLoadingPage(true);
      return;
    }
    if (error || !apiData) {
      setLoadingPage(false);
      return;
    }

    const primaryColor = apiData?.merchant?.primaryColor;
    const secondaryColor = apiData?.merchant?.secondaryColor;

    setValidTemplate(
      isPossibleTemplate(apiData?.merchant?.preferredTemplate as number)
    );

    const awaitColorChange = async (
      primaryColor: Nullable<string>,
      secondaryColor: Nullable<string>
    ) => {
      savePrimaryColor(primaryColor as string);
      saveSecondaryColor(secondaryColor as string);
      await awaitFunction(500, () => null);
      setLoadingPage(false);
    };
    awaitColorChange(primaryColor, secondaryColor);
  }, [apiData, loading, error, savePrimaryColor, saveSecondaryColor]);

  const storeInputsOnLocal = () => {
    if (!apiData) return;
    if (!apiData.storable) return;
    if (apiData.isSubscription) return;

    const inputsToStore = Object.keys(openInputs)
      .filter((key) => {
        if (key === 'amount') return false;
        if (key === 'email' && (apiData.paymentRequest.email || '') !== '')
          return false;
        if (key === 'name' && (apiData.paymentRequest.name || '') !== '')
          return false;
        if (key === 'name' || key === 'email') return true;

        const linkConfigurationValue =
          apiData.paymentRequest.linkConfigurations.filter(
            (lc) => lc.name === key
          )[0]?.value;
        if (linkConfigurationValue !== '') return false;

        return true;
      })
      .reduce((prev, curr) => ({ ...prev, [curr]: openInputs[curr] }), {});

    setItem(`pagohub-${apiData?.merchant.id}`, JSON.stringify(inputsToStore));
  };

  const loadOpenStoredInputs = () => {
    if (!apiData) return;
    if (!apiData.storable) return;
    if (apiData.isSubscription) return;
    if (!loadedInputs) return;

    const updatedOpenInputs = Object.keys(openInputs)
      .map((key) => {
        if (openInputs[key] !== '') return [key, openInputs[key]];
        return [key, loadedInputs[key]];
      })
      .reduce((prev, curr) => ({ ...prev, [curr[0]]: curr[1] }), {});
    setOpenInputs(updatedOpenInputs as OpenInputType);
    setRecheckValidations(true);
  };

  const handleChangeOpenInput = (name: string, value: string | number) => {
    setOpenInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeSubscriptionInput = (
    name: keyof SubscriptionInputType,
    value: string | number | boolean,
    type: string
  ) => {
    if (type === 'checkbox') {
      setSubscriptionInputs((values) => ({
        ...values,
        [name]: !subscriptionInputs[name],
      }));
      return;
    }
    setSubscriptionInputs((values) => ({
      ...values,
      [name]: value,
    }));
  };

  const onDismissField = (name: string, dismiss: boolean) => {
    if (dismiss) {
      setOpenInputs((values) => {
        // dismissedValue will not be used
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [name]: dismissedValue, ...updatedValues } = values;
        return updatedValues as OpenInputType;
      });
    }
    setDismissedFields((values) => ({ ...values, [name]: dismiss }));
  };

  const handleOpenSubmit = () => {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = `${LINKER_URL}/transactions/create?fill=${apiData?.paymentRequest?.id}`;

    const hiddenFieldSample = document.createElement('input');
    hiddenFieldSample.type = 'hidden';
    hiddenFieldSample.name = 'payment_request_variations_open';
    hiddenFieldSample.value = JSON.stringify(openInputs);
    form.appendChild(hiddenFieldSample);

    Object.entries(openInputs).forEach(([key, value]) => {
      const hiddenInputField = document.createElement('input');
      hiddenInputField.type = 'hidden';
      hiddenInputField.name = key;
      hiddenInputField.value = `${value}`;
      form.appendChild(hiddenInputField);
    });

    const hiddenFieldMetricToken = document.createElement('input');
    hiddenFieldMetricToken.type = 'hidden';
    hiddenFieldMetricToken.name = 'metrics_token';
    hiddenFieldMetricToken.value = apiData?.metricsToken || '';
    form.appendChild(hiddenFieldMetricToken);

    document.body.appendChild(form);
    form.submit();
  };

  const handleSubscriptionSubmit = async () => {
    if (apiData?.generatedLink) {
      await sendMetrics(apiData?.metricsToken || '');

      const form = document.createElement('form');
      form.method = 'POST';
      form.action = apiData.generatedLink;

      document.body.appendChild(form);
      form.submit();
      return;
    }
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = `${LINKER_URL}/transactions/${id}/open_subscription?fill=${apiData?.paymentRequest?.id}`;

    const hiddenFieldSample = document.createElement('input');
    hiddenFieldSample.type = 'hidden';
    hiddenFieldSample.name = 'subscriptions_recurring_payment_request';
    hiddenFieldSample.value = JSON.stringify(subscriptionInputs);
    form.appendChild(hiddenFieldSample);

    Object.entries(subscriptionInputs).forEach(([key, value]) => {
      const hiddenInputField = document.createElement('input');
      hiddenInputField.type = 'hidden';
      hiddenInputField.name = key;
      hiddenInputField.value = `${value}`;
      form.appendChild(hiddenInputField);
    });

    const hiddenFieldMetricToken = document.createElement('input');
    hiddenFieldMetricToken.type = 'hidden';
    hiddenFieldMetricToken.name = 'metrics_token';
    hiddenFieldMetricToken.value = apiData?.metricsToken || '';
    form.appendChild(hiddenFieldMetricToken);

    const slugField = document.createElement('input');
    slugField.type = 'hidden';
    slugField.name = 'slug';
    slugField.value = id as string;
    form.appendChild(slugField);

    document.body.appendChild(form);
    form.submit();
  };

  return (
    <>
      {loadingPage && <LoadingScreen message={null} />}
      {!loadingPage && apiData && validTemplate && (
        <OpenBaseTemplateOne
          openInputs={openInputs}
          subscriptionInputs={subscriptionInputs}
          loadedInputs={loadedInputs}
          loadOpenStoredInputs={loadOpenStoredInputs}
          storeInputsOnLocal={storeInputsOnLocal}
          handleChangeOpenInput={handleChangeOpenInput}
          handleChangeSubscriptionInput={handleChangeSubscriptionInput}
          apiData={apiData}
          dismissedFields={dismissedFields}
          onDismissField={onDismissField}
          handleOpenSubmit={handleOpenSubmit}
          handleSubscriptionSubmit={handleSubscriptionSubmit}
          recheckValidations={recheckValidations}
          setRecheckValidations={setRecheckValidations}
        />
      )}
      {!loadingPage && (error || !validTemplate) && <ErrorScreen />}
    </>
  );
};

export default Open;
