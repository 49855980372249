import isRut from '../../isRut';

const allowedValidations: {
  [key: string]: {
    [key: string]: {
      message: string;
      validator: (input: string) => [string, boolean];
    };
  };
} = {
  'LinkConfigurations::Text': {
    is_rut: {
      message: 'Debe ser un rut válido',
      validator: isRut,
    },
  },
  'LinkConfigurations::RemoteText': {
    is_rut: {
      message: 'Debe ser un rut válido',
      validator: isRut,
    },
  },
};

export default allowedValidations;
