import {
  ChangeEvent,
  FC,
  FocusEvent,
  MouseEvent,
  useEffect,
  useState,
} from 'react';
import axios from 'axios';

// middlewares
import {
  LinkConfigurationsOutputData,
  OpenInputType,
} from '../../../../middlewares/Open/openTypes';

// hooks
import useColors from '../../../../hooks/useColors';

// utils
import { LINKER_URL } from '../../../../utils/urls';

// icon
import { ReactComponent as TrashIcon } from '../../../../assets/feather/trash-2.svg';
import { ReactComponent as AlertIcon } from '../../../../assets/feather/alert-octagon.svg';

interface RemoteTextFieldProps {
  inputs: OpenInputType;
  linkConfiguration: LinkConfigurationsOutputData;
  onChangeInput: (name: string, value: string | number) => void;
  dismissed: boolean;
  onDismissField: (name: string, dismiss: boolean) => void;
  error: string | null;
}

const RemoteTextField: FC<RemoteTextFieldProps> = (props) => {
  const {
    inputs,
    onChangeInput,
    linkConfiguration,
    dismissed,
    onDismissField,
    error,
  } = props;
  const { primaryColor } = useColors();
  const [writtenInput, setWrittenInput] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [options, setOptions] = useState<
    Array<{ name: string; identifier: string }>
  >([]);

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onChangeInput(linkConfiguration.name, value);
    setWrittenInput(value);
  };

  const loadOptions: (query: string | number) => Promise<void> = async (
    query
  ) => {
    const url = `${LINKER_URL.split('/app')[0]}/transaction/data/fill_content?list_type=${
      linkConfiguration.data?.type
    }&q=${query}&search_field=${linkConfiguration.data?.field || 'identifier'}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    try {
      setLoading(true);
      const res = await axios.get(url, { headers });
      if (res.status === 200) {
        const { data } = res;
        setOptions(data.jlist.slice(0, 100));
      } else {
        console.log(res);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleBlur = (e: FocusEvent<HTMLElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setIsFocus(false);
    }
  };

  const handleDismissButton = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onDismissField(linkConfiguration.name, true);
    setIsFocus(false);
  };

  const handleOptionClick = (option: { name: string; identifier: string }) => {
    const value = option[linkConfiguration.data?.field as keyof typeof option];
    const linkedValue =
      option[linkConfiguration.data?.linked.field as keyof typeof option];
    onChangeInput(linkConfiguration.name, value);
    onChangeInput(linkConfiguration.data?.linked.target as string, linkedValue);
    setIsFocus(false);
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => loadOptions(writtenInput), 500);
    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [writtenInput]);

  return (
    <div
      className={`mx-auto relative w-11/12 text-left ${linkConfiguration.hidden || dismissed ? 'hidden' : ''}`}
      onFocus={() => setIsFocus(true)}
      onBlur={handleBlur}
    >
      <div className="flex justify-between">
        <label htmlFor="name" className="text-sm font-semibold w-full mr-auto">
          {linkConfiguration.label}:
        </label>
        {linkConfiguration.dismissable && (
          <button className="max-h-4 my-auto" onClick={handleDismissButton}>
            <TrashIcon className="max-h-4 my-auto text-stone-500" />
          </button>
        )}
      </div>
      <div>
        <input
          id={linkConfiguration.name}
          name={linkConfiguration.name}
          placeholder={linkConfiguration.placeholder}
          value={inputs[linkConfiguration.name]}
          onChange={handleChangeInput}
          className="bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5 disabled:bg-[#D2D2D2]"
          style={{
            borderColor: isFocus ? primaryColor : '#D2D2D2',
            outlineColor: isFocus ? primaryColor : 'transparent',
          }}
        />
        {loading && (
          <div className="absolute inset-y-0 top-6 end-0 flex items-center pe-3.5 pointer-events-none">
            <div
              aria-label="Loading..."
              role="status"
              className="flex items-center"
            >
              <svg
                className="h-5 w-5 animate-spin"
                viewBox="0 0 256 256"
                style={{
                  stroke: primaryColor,
                  transition: 'all .4s ease',
                  WebkitTransition: 'all .4s ease',
                  MozTransition: 'all .4s ease',
                }}
              >
                <line
                  x1="128"
                  y1="32"
                  x2="128"
                  y2="64"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="24"
                ></line>
                <line
                  x1="195.9"
                  y1="60.1"
                  x2="173.3"
                  y2="82.7"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="24"
                ></line>
                <line
                  x1="224"
                  y1="128"
                  x2="192"
                  y2="128"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="24"
                ></line>
                <line
                  x1="195.9"
                  y1="195.9"
                  x2="173.3"
                  y2="173.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="24"
                ></line>
                <line
                  x1="128"
                  y1="224"
                  x2="128"
                  y2="192"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="24"
                ></line>
                <line
                  x1="60.1"
                  y1="195.9"
                  x2="82.7"
                  y2="173.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="24"
                ></line>
                <line
                  x1="32"
                  y1="128"
                  x2="64"
                  y2="128"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="24"
                ></line>
                <line
                  x1="60.1"
                  y1="60.1"
                  x2="82.7"
                  y2="82.7"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="24"
                ></line>
              </svg>
            </div>
          </div>
        )}
      </div>
      <div
        id="dropdownHelper"
        style={{
          display: isFocus ? 'block' : 'none',
          position: isFocus ? 'absolute' : 'inherit',
        }}
        className="absolute w-full max-h-60 overflow-y-auto z-40 bg-white divide-y divide-gray-100 rounded-lg shadow w-inherit"
      >
        <ul
          className="p-3 space-y-1 text-sm text-gray-700"
          aria-labelledby="dropdownRadioHelperButton"
        >
          {options.map((option, i) => (
            <li key={`${option.identifier}-${i}`} tabIndex={0}>
              <div className="flex p-2 rounded hover:bg-gray-100">
                <input
                  id={`helper-radio-${linkConfiguration.id}-${option[linkConfiguration.data?.field as keyof typeof option]}`}
                  name={`helper-radio-${linkConfiguration.id}-${option[linkConfiguration.data?.field as keyof typeof option]}`}
                  type="checkbox"
                  value=""
                  onClick={() => handleOptionClick(option)}
                  className="hidden peer w-full"
                />
                <label
                  htmlFor={`helper-radio-${linkConfiguration.id}-${option[linkConfiguration.data?.field as keyof typeof option]}`}
                  className="text-sm min-w-full font-medium text-gray-900 cursor-pointer"
                >
                  <div className="w-full">
                    {option.identifier} - {option.name}
                  </div>
                </label>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {error && (
        <div className="flex text-red-500">
          <AlertIcon className="h-4 my-auto" />
          <p className="text-sm">{error}</p>
        </div>
      )}
    </div>
  );
};

export default RemoteTextField;
