function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function awaitFunction(ms: number, fn: any, ...args: any) {
  await timeout(ms);
  return fn(...args);
}

export default awaitFunction;
