import { ChangeEvent, FC, FormEvent, useState } from 'react';
import Modal from '../../../common/Modal';
import {
  PaymentMethodOutputData,
  SubscriptionCardOutputData,
} from '../../../../middlewares/Payments/types';
import Button from '../../../shared/Button';
import { PORTAL_URL } from '../../../../utils/urls';
import { useParams, useSearchParams } from 'react-router-dom';
import { encondeSubscriptionCard } from '../../../../utils/payments/cipher';
import useIsTemplate from '../../../../hooks/useIsTemplate';
// import sendMetrics from '../../../../utils/metrics/sendMetrics';
import { Nullable } from '../../../../utils/customTypes';

interface ConfirmPaymentCardPasswordProps {
  subscriptionCard: SubscriptionCardOutputData;
  paymentMethod: PaymentMethodOutputData;
  metricsToken: Nullable<string>;
  closeModal: () => void;
}

const ConfirmPaymentCardPassword: FC<ConfirmPaymentCardPasswordProps> = (
  props
) => {
  const { subscriptionCard, paymentMethod, metricsToken, closeModal } = props;
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const price = searchParams.get('price');
  const { id } = useParams();
  const { isTemplate } = useIsTemplate();
  const [inputs, setInputs] = useState<{ [key: string]: string }>({
    password: '',
  });
  const [loadingPayButton, setLoadingPayButton] = useState<boolean>(false);
  const maxPasswordLength = 6;

  const handlePassChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setInputs((values) => ({
      ...values,
      [name]: value,
    }));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    setLoadingPayButton(true);
    if (isTemplate) {
      e.preventDefault();
      window.location.href = `/payments/template/return?token=${token}&price=${price}`;
      setLoadingPayButton(false);
    }
  };

  return (
    <Modal title="Confirmación de pago" closeModal={closeModal}>
      <div className="px-5 pb-5">
        <form
          action={`${PORTAL_URL}/payments/pay${metricsToken ? `?metrics_token=${metricsToken}` : ''}`}
          method="POST"
          onSubmit={handleSubmit}
        >
          <p>
            Se le envió una contraseña al correo indicado en su suscripción
            <br />
            para confirmar su identidad.
          </p>

          <div className="flex w-full justify-evenly my-5">
            <div className="flex">
              <img
                src={subscriptionCard.cardLogo}
                className="my-auto max-h-12 max-w-32"
              />
            </div>
            <div className="text-sm">
              <p>xxxx-{subscriptionCard.cardDigits}</p>
              <p>{subscriptionCard.name}</p>
              <p>{subscriptionCard.email}</p>
            </div>
          </div>

          <div>
            <div className="field flex justify-center align-middle mb-2">
              <label
                className="input-group-addon text-sm flex items-center"
                htmlFor="pass"
              >
                Contraseña:
              </label>
              <input
                type="text"
                name="password"
                id="password"
                maxLength={maxPasswordLength}
                value={inputs.password}
                onChange={handlePassChange}
                className="form-control mx-2 px-2 py-1 text-sm rounded-md border-2"
              />
            </div>
          </div>
          <input hidden readOnly name="payment_id" value={id} />
          <input
            hidden
            readOnly
            name="payment_method_id"
            value={`${paymentMethod.name}|${encondeSubscriptionCard(subscriptionCard)}`}
          />

          <Button
            text={
              loadingPayButton ? (
                <svg
                  className="animate-spin mx-5 h-7 w-7 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
              ) : (
                'Pagar'
              )
            }
            onClick={() => null}
            value="pay"
            type="submit"
            disabled={
              inputs.password.length !== maxPasswordLength || loadingPayButton
            }
          />
        </form>
      </div>
    </Modal>
  );
};

export default ConfirmPaymentCardPassword;
