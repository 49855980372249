import { FC, useState } from 'react';
import {
  PaymentMethodOutputData,
  SubscriptionCardOutputData,
} from '../../../../middlewares/Payments/types';
import { Nullable } from '../../../../utils/customTypes';
import useColors from '../../../../hooks/useColors';
import logoParser from '../../../../utils/logoParser';
import OneClickMandateDetails from '../Options/OneClickMandateDetails';
import isSubscriptionPaymentMethod from '../../../../utils/payments/isSubscriptionPaymentMethod';

interface SelectedPaymentDetailsProps {
  paymentMethod: PaymentMethodOutputData;
  subscriptionCards: Array<SubscriptionCardOutputData>;
  subscriptionCardsLoading: boolean;
  selectedCard: Nullable<SubscriptionCardOutputData>;
  isPartOfMultiPaymentMethods: boolean;
  handleUnSelect: (paymentMethod: Nullable<PaymentMethodOutputData>) => void;
  handleSelectSubscriptionCard: (
    subscriptionCard: SubscriptionCardOutputData
  ) => void;
  handleTryDeleteSubscription: (sub: SubscriptionCardOutputData) => void;
}

const SelectedPaymentDetails: FC<SelectedPaymentDetailsProps> = (props) => {
  const {
    paymentMethod,
    subscriptionCards,
    subscriptionCardsLoading,
    selectedCard,
    isPartOfMultiPaymentMethods,
    handleUnSelect,
    handleSelectSubscriptionCard,
    handleTryDeleteSubscription,
  } = props;
  const { primaryColor, secondaryColor } = useColors();
  const [isHover, setIsHover] = useState(false);
  const isSubscriptionOrOneClick: boolean = isSubscriptionPaymentMethod(
    paymentMethod.handler
  );

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <div className="w-full h-full my-6 lg:mt-0 flex flex-col justify-between">
      <div className="w-full h-full">
        {isPartOfMultiPaymentMethods && (
          <button
            type="button"
            className="w-full flex items-center justify-start mx-5 my-2 text-sm transition-colors duration-200 sm:w-auto"
            style={{ color: isHover ? secondaryColor : primaryColor }}
            onClick={() => handleUnSelect(null)}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <svg
              className="w-5 h-5 rtl:rotate-180"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 15.75L3 12m0 0l3.75-3.75M3 0"
              />
            </svg>
            <span>Elegir otro medio de pago</span>
          </button>
        )}
        <div
          className="w-full my-6 py-2 m-auto text-left cursor-pointer"
          style={{
            marginBottom: isSubscriptionOrOneClick ? '0' : '1.5rem',
          }}
        >
          <img
            className="mx-auto max-w-32 min-h-8 lg:max-w-64"
            style={{
              maxHeight: isSubscriptionOrOneClick ? '4rem' : '15vh',
            }}
            src={logoParser(paymentMethod.logo || '')}
          />
        </div>
        <h1
          className="w-full mb-2 font-extrabold text-center my-auto"
          style={{
            fontSize: isSubscriptionOrOneClick ? '1.125rem' : '1.5rem',
            lineHeight: isSubscriptionOrOneClick ? '1.75rem' : '2rem',
          }}
        >
          {paymentMethod.title}
        </h1>
        <p
          className="w-full text-lg font-normal text-center my-auto"
          style={{
            fontSize: isSubscriptionOrOneClick ? '1rem' : '1.125rem',
            lineHeight: isSubscriptionOrOneClick ? '1.5rem' : '1.75rem',
          }}
        >
          {paymentMethod.description}
        </p>
        {isSubscriptionOrOneClick && (
          <OneClickMandateDetails
            paymentMethod={paymentMethod}
            subscriptionCards={subscriptionCards}
            subscriptionCardsLoading={subscriptionCardsLoading}
            selectedCard={selectedCard}
            handleSelectSubscriptionCard={handleSelectSubscriptionCard}
            handleTryDeleteSubscription={handleTryDeleteSubscription}
          />
        )}
      </div>
    </div>
  );
};

export default SelectedPaymentDetails;
