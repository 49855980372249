import { FC, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import ReactGA from 'react-ga';

// hooks
import useColors from '../hooks/useColors';
import useLinkerFetch from '../hooks/useLinkerFetch';

// middlewares
import returnParser from '../middlewares/Return/returnParser';

// components
import LoadingScreen from '../components/common/LoadingScreen';
import ErrorScreen from '../components/common/ErrorScreen';
import ReturnBaseTemplateOne from '../components/t1/return/Base/ReturnBaseTemplateOne';

// utils
import awaitFunction from '../utils/awaitFunction';
import { Nullable } from '../utils/customTypes';
import isPossibleTemplate from '../utils/isPossibleTemplates';

const Return: FC = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { savePrimaryColor, saveSecondaryColor } = useColors();
  const [validTemplate, setValidTemplate] = useState<boolean>(false);
  const [loadingPage, setLoadingPage] = useState<boolean>(true);
  const token = searchParams.get('token');
  const confirmation = searchParams.get('confirmation');
  const update = searchParams.get('update');

  const generateReturnQueryParam = () => {
    if (token) return `token=${token}`;
    if (confirmation) return `confirmation=${confirmation}`;
    if (update) return `update=${update}`;
    return '';
  };

  const { apiData, loading, error } = useLinkerFetch(
    `transactions/${id}/return?${generateReturnQueryParam()}`,
    returnParser
  );

  // Google analytics
  useEffect(() => {
    if (loading || !apiData || error) return;

    if (apiData.merchant.ganalyticsToken) {
      ReactGA.initialize(apiData.merchant.ganalyticsToken);
      ReactGA.pageview(window.location.pathname + window.location.search);
      if (
        apiData.payment.statusMessage &&
        (apiData.payment.statusMessage.toString().includes('exito') ||
          apiData.payment.statusMessage.toString().includes('xitosa') ||
          apiData.payment.statusMessage.toString().includes('xitoso'))
      ) {
        ReactGA.event({
          category: 'Conversion',
          action: 'Successful Payment',
          label: 'Website traffic conversion page',
        });
      }
    }
  }, [apiData, loading, error]);

  useEffect(() => {
    if (loading) {
      setLoadingPage(true);
      return;
    }
    if (error || !apiData) {
      setLoadingPage(false);
      return;
    }
    const primaryColor = apiData?.merchant?.primaryColor;
    const secondaryColor = apiData?.merchant?.secondaryColor;

    setValidTemplate(
      isPossibleTemplate(apiData?.merchant?.preferredTemplate as number)
    );

    const awaitColorChange = async (
      primaryColor: Nullable<string>,
      secondaryColor: Nullable<string>
    ) => {
      savePrimaryColor(primaryColor as string);
      saveSecondaryColor(secondaryColor as string);
      await awaitFunction(500, () => null);
      setLoadingPage(false);
    };
    awaitColorChange(primaryColor, secondaryColor);
  }, [apiData, loading, error, savePrimaryColor, saveSecondaryColor]);

  return (
    <>
      {loadingPage && <LoadingScreen message={null} />}
      {!loadingPage && apiData && validTemplate && (
        <ReturnBaseTemplateOne apiData={apiData} />
      )}
      {!loadingPage && (error || !validTemplate) && <ErrorScreen />}
    </>
  );
};

export default Return;
