import { ChangeEvent, FC } from 'react';

// middlewares
import {
  PaymentRequestOutputData,
  SubscriptionInputType,
} from '../../../../middlewares/Open/openTypes';

// hooks
import useColors from '../../../../hooks/useColors';

// components
import CustomInput from '../../common/CustomInput';

// icons
import { ReactComponent as AlertIcon } from '../../../../assets/feather/alert-octagon.svg';

interface SubscriptionInformationProps {
  paymentRequestData: PaymentRequestOutputData;
  subscriptionInputs: SubscriptionInputType;
  handleChangeInput: (
    name: keyof SubscriptionInputType,
    value: string | number | boolean,
    type: string
  ) => void;
}

const SubscriptionInformation: FC<SubscriptionInformationProps> = (props) => {
  const { paymentRequestData, subscriptionInputs, handleChangeInput } = props;
  const { secondaryColor } = useColors();

  const handleChangeAmount = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    handleChangeInput('amount', value, 'number');
  };

  return (
    <div className="w-full lg:w-1/2 text-justify px-10 lg:pl-15">
      <h1 className="text-2xl font-bold mb-5">Suscripción</h1>
      <p>Verifica la información de la suscripción</p>
      <div className="mx-auto lg:mx-0 w-[95%] text-left my-4">
        <label
          htmlFor="name"
          className="text-sm font-semibold w-full mr-auto"
          style={{ color: secondaryColor }}
        >
          Monto suscrito:
        </label>
        <CustomInput
          type="number"
          id="amount"
          name="amount"
          placeholder="Ingrese el monto"
          autoComplete="off"
          value={subscriptionInputs.amount}
          min={1}
          max={100000000000}
          handleChange={handleChangeAmount}
          disabled={
            paymentRequestData.amount !== 0 &&
            paymentRequestData.amount !== null
          }
          icon={<p>$</p>}
          currency={
            paymentRequestData.currency ? (
              <p>{paymentRequestData.currency?.toUpperCase()}</p>
            ) : null
          }
        />
        {subscriptionInputs.amount &&
          (subscriptionInputs.amount as number) <= 0 && (
            <div className="flex text-red-500">
              <AlertIcon className="h-4 my-auto" />
              <p className="text-sm">El monto debe ser mayor a 0</p>
            </div>
          )}
      </div>
      <div className="mx-auto lg:mx-0 w-[95%] text-left my-4">
        <label
          htmlFor="email"
          className="text-sm font-semibold w-full mr-auto"
          style={{ color: secondaryColor }}
        >
          Identificador:
        </label>
        <CustomInput
          id="identifier"
          name="identifier"
          placeholder="Indica el identificador de la suscripción"
          value={paymentRequestData.identifier as string}
          disabled
        />
      </div>
    </div>
  );
};

export default SubscriptionInformation;
