import { FC, useState } from 'react';
import { PaymentMethodOutputData } from '../../../../middlewares/Payments/types';
import useColors from '../../../../hooks/useColors';
import textWrapper from '../../../../utils/textWrapper';
import logoParser from '../../../../utils/logoParser';
import { Nullable } from '../../../../utils/customTypes';

interface CardDetailsSelectProps {
  paymentMethod: PaymentMethodOutputData;
  handleChange: (paymentMethod: Nullable<PaymentMethodOutputData>) => void;
}

const CardDetailsSelect: FC<CardDetailsSelectProps> = (props) => {
  const { paymentMethod, handleChange } = props;
  const { primaryColor } = useColors();
  const [isHover, setIsHover] = useState(false);
  const isSelected = false;

  const handlePaymentMethodClick: (
    paymentMethod: PaymentMethodOutputData
  ) => void = (paymentMethod) => {
    handleChange(paymentMethod);
  };
  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <div className="w-[47%] p-0 lg:p-2">
      <input
        id={`checkbox-detail-${paymentMethod.slug}`}
        className="hidden peer"
        type="checkbox"
        // checked={isSelected}
        value=""
        style={{ accentColor: primaryColor }}
        onChange={() => handlePaymentMethodClick(paymentMethod)}
      />
      <label
        htmlFor={`checkbox-detail-${paymentMethod.slug}`}
        className="
          min-w-full min-h-24 border-[1px] shadow-md
          flex flex-col justify-between rounded-lg cursor-pointer 
          hover:border-2 space-x-1 px-4 lg:px-7
        "
        style={{
          borderColor: isHover || isSelected ? primaryColor : '#cac9c9',
          borderWidth: isHover || isSelected ? '3px' : '1px',
          color: isSelected ? primaryColor : 'black',
          backgroundColor: isHover ? '#F8F8F8' : 'white',
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="w-full py-2 m-auto text-left cursor-pointer">
          <img
            className="mx-auto max-h-6"
            src={logoParser(paymentMethod.logo || '')}
          />
        </div>
        <h1 className="w-full text-xs md:text-md font-semibold text-center my-auto cursor-pointer">
          {textWrapper(paymentMethod.title, 47)}
        </h1>
      </label>
    </div>
  );
};

export default CardDetailsSelect;
