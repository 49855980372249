import { OpenParserInputData } from '../openTypes';

const subscriptionOpenTemplateData: OpenParserInputData = {
  status: 200,
  data: {
    generated_link: null,
    subscription: true,
    metrics_token: '0',
    storable: false,
    merchant: {
      id: 1,
      name: 'Prueba',
      logo: null,
      colors: '{}',
      preferred_template: 0,
    },
    payment_request: {
      id: 'aaa',
      identifier: 'example-identifier',
      name: 'Cliente de prueba',
      email: '',
      amount: 0,
      currency: null,
      blank_amount: false,
      has_iva: false,
      has_link_configurations: true,
      link_configurations: [],
    },
  },
};

export default subscriptionOpenTemplateData;
