const ENV_PORTAL_URL = process.env.REACT_APP_PORTAL_URL;
const ENV_LINKER_URL = process.env.REACT_APP_LINKER_URL;
const ENV_CLOUDFRONT_URL = process.env.REACT_APP_CLOUDFRONT_URL;

export const PORTAL_URL = ENV_PORTAL_URL
  ? `${ENV_PORTAL_URL}`
  : 'http://localhost:3001';
export const LINKER_URL = ENV_LINKER_URL
  ? `${ENV_LINKER_URL}`
  : 'http://localhost:3099';

export const CLOUDFRONT_URL = ENV_CLOUDFRONT_URL ? `${ENV_CLOUDFRONT_URL}` : '';

export const TERMS_AND_CONDITIONS_URL =
  'https://alp-prod.s3.amazonaws.com/legal/Terminos_y_Condiciones_v2.pdf';
