import { FC } from 'react';
import {
  MerchantOutputData,
  TransactionRequestOutputData,
} from '../../../../middlewares/Return/returnTypes';
import useColors from '../../../../hooks/useColors';

interface PaymentInformationProps {
  transactionRequest: TransactionRequestOutputData;
  merchant: MerchantOutputData;
}

const PaymentInformation: FC<PaymentInformationProps> = (props) => {
  const { transactionRequest, merchant } = props;
  const { secondaryColor } = useColors();

  return (
    <div className="w-full my-auto py-4 md:w-1/2">
      <h1 className="text-lg font-bold mb-4" style={{ color: secondaryColor }}>
        Acerca del pago:
      </h1>
      <div className="px-8">
        <div className="flex justify-center md:justify-between">
          <p className="font-medium">Monto a pagar:</p>
          <p className="ml-2 md:ml-auto">${transactionRequest.amount}</p>
        </div>
        <div className="flex justify-center md:justify-between">
          <p className="font-medium">Identificador:</p>
          <p className="ml-2 md:ml-auto">{transactionRequest.identifier}</p>
        </div>
        <div className="flex justify-center md:justify-between">
          <p className="font-medium">Cliente:</p>
          <p className="ml-2 md:ml-auto">{transactionRequest.name}</p>
        </div>
        <div className="flex justify-center md:justify-between">
          <p className="font-medium">Pagado en:</p>
          <p className="ml-2 md:ml-auto">{merchant.name}</p>
        </div>
      </div>
    </div>
  );
};

export default PaymentInformation;
