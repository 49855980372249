import { useContext } from 'react';
import { ColorsContext } from '../contexts/ColorsContext';

const useColors = () => {
  const { primaryColor, secondaryColor, savePrimaryColor, saveSecondaryColor } =
    useContext(ColorsContext);

  return {
    primaryColor,
    secondaryColor,
    savePrimaryColor,
    saveSecondaryColor,
  };
};

export default useColors;
