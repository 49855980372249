import { FC } from 'react';
import { CLOUDFRONT_URL } from '../../utils/urls';

interface ColorPickerIconProps {
  openModal: (open: boolean) => void;
}

const ColorPickerIcon: FC<ColorPickerIconProps> = (props) => {
  const { openModal } = props;

  return (
    <div className="fixed flex w-16 h-16 rounded-full bottom-10 left-5">
      <button className="p-0 w-16 h-16" onClick={() => openModal(true)}>
        <img
          src={`${CLOUDFRONT_URL}/payment-gateway/t1/colorPicker.webp`}
          className="w-16 h-16"
          alt="Elegir color"
        />
        <div className="opacity-0 hover:opacity-100 duration-200 absolute inset-0 z-[5] flex justify-center items-center text-sm pl-24 text-black font-semibold">
          Cambiar colores
        </div>
      </button>
    </div>
  );
};

export default ColorPickerIcon;
