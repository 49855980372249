import './styles/App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// contexts
import ColorsProvider from './contexts/ColorsContext';
import IsTemplateProvider from './contexts/isTemplateContext';

// pages
import Open from './pages/Open';
import Payments from './pages/Payments';
import Redirect from './pages/Redirect';
import Return from './pages/Return';

// templates
import OpenTemplate from './pages/template/Open';
import PaymentsTemplate from './pages/template/Payments';
import ReturnTemplate from './pages/template/Return';

function App() {
  return (
    <div className="App">
      <ColorsProvider>
        <IsTemplateProvider>
          <BrowserRouter>
            <Routes>
              <Route>
                <Route
                  path="/home"
                  element={<Redirect url={'https://pagohub.cl/'} />}
                />
                <Route
                  path="/payments/template"
                  element={<PaymentsTemplate />}
                />
                <Route
                  path="/payments/template/return"
                  element={<ReturnTemplate />}
                />
                <Route
                  path="/payments/template/:type"
                  element={<OpenTemplate />}
                />
                <Route path="/payments/:id" element={<Payments />} />
                <Route path="/payments/:id/open" element={<Open />} />
                <Route path="/payments/:id/return" element={<Return />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </IsTemplateProvider>
      </ColorsProvider>
    </div>
  );
}

export default App;
