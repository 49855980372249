import { FC } from 'react';
import useColors from '../../../hooks/useColors';

import './../../../styles/footer.css';

const Footer: FC<object> = () => {
  const { primaryColor, secondaryColor } = useColors();

  return (
    <footer className="h-[30px] w-screen bg-card border-tshadow flex">
      <div
        className="w-3/12 lg:w-6/12 h-full"
        style={{ backgroundColor: primaryColor }}
      />
      <div
        className="w-0 lg:w-3/12 h-full"
        style={{ backgroundColor: secondaryColor }}
      />
      <div className="flex w-6/12 lg:w-3/12">
        <div className="footer-logo flex m-auto border-b-[#5F6266]">
          <span className="footer-logo__powered">powered by</span>
          <span className="footer-logo__pago">Pago</span>
          <span className="footer-logo__hub">Hub</span>
        </div>
      </div>
      <div
        className="w-3/12 h-full lg:hidden"
        style={{ backgroundColor: secondaryColor }}
      />
    </footer>
  );
};

export default Footer;
