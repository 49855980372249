import { FC } from 'react';
import amountParser from '../../../../utils/amountParser';
import { Nullable } from '../../../../utils/customTypes';
import useColors from '../../../../hooks/useColors';

interface PaymentHeaderProps {
  merchantLogo: Nullable<string>;
  merchantName: string;
  transactionAmount: number;
}

const PaymentHeader: FC<PaymentHeaderProps> = (props) => {
  const { merchantLogo, merchantName, transactionAmount } = props;
  const { secondaryColor } = useColors();

  return (
    <div className="h-1/4 flex flex-col lg:flex-row justify-between py-3 lg:py-7">
      <div className="h-full flex">
        <div className="mx-auto my-auto lg:mx-0">
          {merchantLogo ? (
            <img src={merchantLogo} className="max-h-16 max-w-96" alt="logo" />
          ) : (
            <h1 className="text-4xl font-roboto font-bold">
              {merchantName || ''}
            </h1>
          )}
        </div>
      </div>
      <div className="flex mx-auto my-auto lg:mx-0 text-4xl">
        <h3
          className="mr-6 font-semibold lg:mr-12"
          style={{ color: secondaryColor }}
        >
          Monto:
        </h3>
        <h3>{amountParser(transactionAmount)}</h3>
      </div>
    </div>
  );
};

export default PaymentHeader;
