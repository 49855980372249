import { FC } from 'react';

// middlewares
import { SubscriptionInputType } from '../../../../middlewares/Open/openTypes';

// hooks
import useColors from '../../../../hooks/useColors';

// utils
import { TERMS_AND_CONDITIONS_URL } from '../../../../utils/urls';

interface TermsAndConditionsProps {
  subscriptionInputs: SubscriptionInputType;
  handleChangeInput: (
    name: keyof SubscriptionInputType,
    value: string | number | boolean,
    type: string
  ) => void;
}

const TermsAndConditions: FC<TermsAndConditionsProps> = (props) => {
  const { subscriptionInputs, handleChangeInput } = props;
  const { primaryColor } = useColors();

  const handleChangeTermsInput = () => {
    handleChangeInput('termsAndConditions', '', 'checkbox');
  };

  return (
    <div className="w-full my-4">
      <div>
        <input
          id={`checkbox`}
          type="checkbox"
          checked={subscriptionInputs.termsAndConditions}
          style={{ accentColor: primaryColor }}
          onChange={handleChangeTermsInput}
          // onChange={() => handlePaymentMethodClick(paymentMethodId)}
        />
        <label htmlFor="checkbox" className="text-sm pl-4">
          Acepto los términos y condiciones
        </label>
      </div>
      <div className="p-1 mx-auto w-1/2 mt-1 bg-gray-300 rounded-xl">
        <a
          href={TERMS_AND_CONDITIONS_URL}
          target="_blank"
          rel="noreferrer noopener"
          className=""
          style={{
            color: primaryColor,
            borderBottom: '1px solid',
            borderColor: primaryColor,
          }}
        >
          Documento Términos y condiciones
        </a>
      </div>
    </div>
  );
};

export default TermsAndConditions;
