import { SubscriptionCardOutputData } from '../../middlewares/Payments/types';

const intToSensitiveChars = (num: number) => {
  const low = 65; // A
  const high = 90; // Z
  const a = 97; // a
  const chars = high - low; // 25

  const digits = 10; // digits

  const mayusLimit = chars;
  const minusLimit = chars * 2 + 1;
  const characters = minusLimit + digits + 1;
  let str = '';
  let parsedNum = num;
  while (parsedNum >= 0) {
    const n = parsedNum % characters;
    const _s =
      n <= mayusLimit
        ? String.fromCharCode(n + low)
        : n <= minusLimit
          ? String.fromCharCode(n - mayusLimit - 1 + a)
          : n - minusLimit - 1;
    str = _s + str;
    parsedNum = Math.floor(parsedNum / characters) - 1;
  }
  return str;
};

const encodeNumber = (num: number) => {
  return intToSensitiveChars(num);
};

export const encondeSubscriptionCard = (sub: SubscriptionCardOutputData) => {
  return `${encodeNumber(sub.createdAt)}-${encodeNumber(sub.id)}`;
};
