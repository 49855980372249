import { FC } from 'react';
import { Nullable } from '../../utils/customTypes';
import LoadingSpinner from '../shared/LoadingSpinner';

interface LoadingScreenProps {
  message: Nullable<string>;
}

const LoadingScreen: FC<LoadingScreenProps> = (props) => {
  const { message } = props;

  return (
    <div className="w-screen h-screen flex">
      <div className="m-auto flex">
        <LoadingSpinner message={message} />
      </div>
    </div>
  );
};

export default LoadingScreen;
